<!--cloud订单-->
<div class="myAccount_right">
  <div *ngIf="init.is_loading;else cloudDetailBlock" style="text-align: center"> 获取中... </div>
  <ng-template #cloudDetailBlock>
    <div class="orderInfo">
      <h5>收货信息</h5>
      <p><span>收货人：</span>{{cloudOrderResult.receiverName}}</p>
      <p><span>手机：</span>{{cloudOrderResult.receiverMobile}}</p>
      <p class="clearfix"><span class="pull-left">地址：</span><em class="pull-left">{{cloudOrderResult.receiverState}}-{{cloudOrderResult.receiverCity}}-{{cloudOrderResult.receiverDistrict}}-{{cloudOrderResult.receiverAddress}}</em></p>
    </div>
    <div class="orderInfo">
      <h5>订单信息</h5>
      <p *ngIf="cloudOrderResult.buyerRemark !== ''"><span>订单备注：</span>{{cloudOrderResult.buyerRemark}}</p>
      <p><span>订单号：</span>{{cloudOrderResult.tid ? cloudOrderResult.tid : cloudOrderResult.orderCode}}</p>
        <p><span>下单时间：</span> {{cloudOrderResult.orderTime.substr(0,10)}}</p>
      <p class="display_ib"><span>订单状态：</span>{{orderService.orderStateTranslateArr[cloudOrderResult.status]}}</p>
      <!-- 未支付订单状态-->
      <em *ngIf="cloudOrderResult.orderType == 'step';else normalOrder">
        <em *ngIf="cloudOrderResult.status === 'WAIT_BUYER_PAY'">
          <!--routerLink="/checkout/payment/{{cloudOrderResult.orderId}}"-->
          <a *ngIf="cloudOrderResult.storeId === 10" (click)="gopay(cloudOrderResult.orderId)"  class="a-button is-primary">支付定金</a>
          &nbsp;<a (click)="cancelOrder(cloudOrderResult.orderId)" class="a-link" href="javascript:void(0)">取消订单</a>
        </em>

        <em *ngIf="cloudOrderResult.status === 'BUYER_PART_PAYMENT' && init.balancePaymentStatus(cloudOrderResult.presaleJson) == '尾款支付已开始' ">
          <a *ngIf="cloudOrderResult.storeId === 10" (click)="gopay(cloudOrderResult.orderId)" class="a-button is-primary">支付尾款</a>
        </em>
      </em>
      <ng-template #normalOrder>
        <em *ngIf="cloudOrderResult.status === 'WAIT_BUYER_PAY'">
          <a *ngIf="cloudOrderResult.storeId === 10" (click)="gopay(cloudOrderResult.orderId)" class="a-button is-primary">付款</a>
          &nbsp;<a (click)="cancelOrder(cloudOrderResult.orderId)" class="a-link" href="javascript:void(0)">取消订单</a>
        </em>
      </ng-template>

      <!--已支付订单2小时内可申请仅退款 -->
      <em *ngIf="orderService.allowJustRefund(cloudOrderResult)">&nbsp;
        <a (click)="applyJustRefund()" class="a-link" href="javascript:void(0)">取消订单</a>
      </em>

      <i *ngIf="allow_confirm">
        <a (click)="cloudOrderComplate(cloudOrderResult.orderId,cloudOrderResult.orderCode)" class="a-button is-primary">确认收货</a>
      </i>
      <em *ngIf="(cloudOrderResult.status === 'WAIT_BUYER_CONFIRM_GOODS' || cloudOrderResult.status === 'TRADE_FINISHED') && cloudOrderResult.shipTime !== null"><a (click)="logistics(cloudOrderResult.orderId)"class="a-button is-primary">查看物流</a></em>
      <em *ngIf="allow_applay_refund"><a routerLink="/my-account/rma/{{cloudOrderResult.orderId}}" class="a-button is-primary">申请退货</a></em>

      <ng-container *ngIf="cloudOrderResult.status == 'TRADE_FINISHED' && cloudOrderResult.invoiceStatus == 0 && invoiceAmount > 0">
        <a [routerLink]="['/my-account/invoice-apply']" [queryParams]="{orderid: orderId}"  class="a-button is-primary">申请发票</a>
      </ng-container>

      <ng-container *ngIf="cloudOrderResult.status == 'TRADE_FINISHED' && cloudOrderResult.invoiceStatus == 2 || cloudOrderResult.status == 'TRADE_FINISHED' && cloudOrderResult.invoiceStatus == 3 || cloudOrderResult.invoiceStatus == 5">
        <a [routerLink]="['/my-account/invoice-detail']" [queryParams]="{orderid: orderId}"  class="a-button is-primary">查看发票</a>
      </ng-container>

      <!--多种支付方式金额-->
      <div class="btnCont" *ngIf="cloudOrderResult.giftCardFee > 0 && cloudOrderResult.notGiftCardFee > 0;else generalPayTpl">
        <span>支付方式：</span>
        <div class="div-box">
         <div>
           <span class="div-span1">微信支付</span>
           <span class="div-span2">{{ cloudOrderResult.notGiftCardFee !== undefined ? "¥ " +cloudOrderResult.notGiftCardFee : 0}}</span>
         </div>
         <div>
           <span class="div-span1">礼品卡支付</span>
           <span class="div-span2">{{ cloudOrderResult.giftCardFee !== undefined ? "¥ " +cloudOrderResult.giftCardFee : 0}}</span>
         </div>
        </div>


        <ng-container *ngIf="cloudOrderResult.status === 'WAIT_BUYER_PAY' && cloudOrderResult.storeId === 10">
          | <u><a href="javascript:void(0)" (click)="changePayment(cloudOrderResult.orderId,cloudOrderResult.payType)" class="">更改</a></u>
        </ng-container>
      </div>

      <ng-template #generalPayTpl>
        <p class="btnCont">
          <span>支付方式：</span>{{orderService.orderPaymentTypeArr[cloudOrderResult.payType]}}
          <ng-container *ngIf="cloudOrderResult.status === 'WAIT_BUYER_PAY' && cloudOrderResult.storeId === 10">
            | <u><a href="javascript:void(0)" (click)="changePayment(cloudOrderResult.orderId,cloudOrderResult.payType)" class="">更改</a></u>
          </ng-container>
        </p>
      </ng-template>



      <!--预售信息-->
      <div *ngIf="cloudOrderResult.orderType == 'step'">
        <div *ngIf="cloudOrderResult.status == 'WAIT_BUYER_PAY'" style="color: #D44511;">
        <p>定金待支付</p>
        <p>订单尾款支付时间为{{init.getBalanceStartTime(cloudOrderResult.presaleJson)}}至{{init.getBalanceEndTime(cloudOrderResult.presaleJson)}}</p>
        </div>
        <div *ngIf="init.balancePaymentStatus(cloudOrderResult.presaleJson) == '尾款支付尚未开始' && cloudOrderResult.status == 'BUYER_PART_PAYMENT'">
        <p  style="color: #D44511;">{{init.getBalanceStartTime(cloudOrderResult.presaleJson)}}开始支付尾款</p>
        <p>请关注COS官方公众号，以便在可支付尾款时收到我们的消息提醒</p>
        </div>
        <div style="color: #D44511;" *ngIf="init.balancePaymentStatus(cloudOrderResult.presaleJson) == '尾款支付已开始' && cloudOrderResult.status == 'BUYER_PART_PAYMENT'">
          <p style="padding-left: 12px;margin-top:10px;">尾款支付已开始</p>
          <p style="padding-left: 12px;">尾款支付截止时间{{init.getBalanceEndTime(cloudOrderResult.presaleJson)}}. 预售商品预计在尾款支付后的48小时内发货</p>
        </div>
        <div *ngIf="init.balancePaymentStatus(cloudOrderResult.presaleJson) == '尾款支付已关闭'">
          <p style="color: #D44511;">尾款支付已关闭</p>
          <p>{{init.getBalanceEndTime(cloudOrderResult.presaleJson)}}尾款支付截止</p>
        </div>

        <div  style="color: #D44511;" *ngIf="init.balancePaymentStatus(cloudOrderResult.presaleJson) == '尾款支付已开始' && cloudOrderResult.status == 'WAIT_SELLER_SEND_GOODS'">
          <p style="padding-left: 12px;margin-top:10px;">尾款支付已支付</p>
          <p style="padding-left: 12px;">预售商品预计在尾款支付后的48小时内发货</p>
        </div>

        <!--支付定金情况-->
        <div *ngIf="cloudOrderResult.status == 'WAIT_SELLER_SEND_GOODS' || cloudOrderResult.status == 'BUYER_PART_PAYMENT';else waitpayTpl" class="amountList">
          <div class="item">
            <div class="ico_s"></div>
            <div class="sTitle"><div class="ico_d"></div>定金（已支付）</div>
            <div>¥&nbsp;{{cloudOrderResult.advancePayment}}</div>
          </div>
          <div class="item discount">
            <div class="ico_s"></div>
            <div class="sTitle"><div class="ico_d"></div>优惠</div>
            <div>¥&nbsp;{{cloudOrderResult.discountFee}}</div>
          </div>
          <div class="item">
            <div class="sTitle" *ngIf="init.balancePaymentStatus(cloudOrderResult.presaleJson) == '尾款支付已关闭';else availableTpl"><div class="ico_d"></div>尾款（已关闭）</div>
            <ng-template #availableTpl>
              <div class="sTitle" *ngIf="cloudOrderResult.status == 'WAIT_SELLER_SEND_GOODS';else othersTpl"><div class="ico_d"></div>尾款（已支付）</div>
              <ng-template #othersTpl>
                <div class="sTitle"><div class="ico_d"></div>尾款</div>
              </ng-template>
            </ng-template>
            <div>¥&nbsp;{{cloudOrderResult.balancePayment}}</div>
          </div>
        </div>
        <ng-template #waitpayTpl>
          <div class="amountList" *ngIf="cloudOrderResult.status == 'WAIT_BUYER_PAY'">
            <div class="item">
              <div class="ico_s"></div>
              <div class="sTitle"><div class="ico_d"></div>定金（待支付）</div>
              <div>¥&nbsp;{{cloudOrderResult.advancePayment}}</div>
            </div>
            <div class="item discount">
              <div class="ico_s"></div>
              <div class="sTitle"><div class="ico_d"></div>优惠</div>
              <div>¥&nbsp;{{cloudOrderResult.discountFee}}</div>
            </div>
            <div class="item">
              <div class="sTitle"><div class="ico_d"></div>尾款</div>
              <div>¥&nbsp;{{cloudOrderResult.balancePayment}}</div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="hidden-sm hidden-xs">
      <table width="100%" border="0" class="orderTable">
        <tr class="font_s_medium font-size-12">
          <th width="34%" colspan="2">商品</th>
          <th width="13%">单价</th>
          <th width="9%">数量</th>
          <th width="11%">订单金额</th>
          <th width="11%">订单状态</th>
          <th width="11%">运费</th>
        </tr>
        <tr>
          <td colspan="4">
            <div  *ngFor="let val of cloudProductInfo; let num = index"  class="gInfo clearfix">
              <div class="pull-left listImg">
                <a *ngIf="val.inValid !== 1" (click)="productUrl(val.skuCode)"><img src="{{orderService.analysisProductImage(val.skuImageUrl, val.skuProperties)}}{{init.imageSize.$4}}" alt="图片" ></a>
                <a *ngIf="val.inValid == 1"><img src="{{orderService.analysisProductImage(val.skuImageUrl, val.skuProperties)}}{{init.imageSize.$2}}" alt="图片" ></a>
              </div>
              <div class="pull-left" style="width: 44%">
                <strong *ngIf="val.inValid !== 1"><a (click)="productUrl(val.skuCode)" class="a-link">{{val.itemName}}</a></strong>
                <strong *ngIf="val.inValid == 1"><a class="a-link">{{val.itemName}}</a></strong>
                <p>货号：{{val.skuCode}}</p>
                <p>颜色：{{orderService.analysisProductColor(val.skuProperties).valueName}}</p>
                <p>尺码：{{orderService.sizeConvert(orderService.analysisProductSize(val.skuProperties).valueName)}}</p>
                <ng-container *ngIf="val['refundLists'].length > 0">
                  <ng-container *ngFor="let _refundVal of val['refundLists']">
                    <p *ngIf="_refundVal.status !== 'CLOSED'">退货状态：{{orderService.refundStateTranslateArr[_refundVal.status]}}&nbsp;&nbsp;&nbsp;&nbsp;<a [routerLink]="['/my-account/rma-detail']" [queryParams]="{id: _refundVal.refundId}" class="a-link-underline">查看详情</a></p>
                  </ng-container>
                </ng-container>
              </div>
              <span class="pull-right quantity">{{val.qty}}件</span>
              <span class="pull-right price">{{init.currency + val.price}}</span>
            </div>
          </td>
          <td>{{init.currency + cloudOrderResult.payment}}</td>
          <td>
            <p class="text-center">{{orderService.orderStateTranslateArr[cloudOrderResult.status]}}</p>
          </td>
          <td>
            <div class="contBox">
              <p class="text-center">{{init.currency + cloudOrderResult.postFee}}</p>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <!--Mobile-->
    <div class="hidden-lg hidden-md">
      <div class="orderTable_Mob">
        <div class="orderItem">
          <div class="topBox font_s_medium">
            <span>订单商品</span>
            <span class="state pull-right">{{orderService.orderStateTranslateArr[cloudOrderResult.status]}}</span>
          </div>

          <!--订单详情，商品invalid默认为null 表示有效 与订单列表返回不一致 需兼容null的情况-->
          <div  *ngFor="let val of cloudProductInfo; let num = index" class="gInfo clearfix">
            <div class="pull-left listImg">
              <a *ngIf="val.inValid !== 1" (click)="productUrl(val.skuCode)"><img src="{{orderService.analysisProductImage(val.skuImageUrl, val.skuProperties)}}{{init.imageSize.$4}}" alt="图片"></a>
              <a *ngIf="val.inValid == 1"><img src="{{orderService.analysisProductImage(val.skuImageUrl, val.skuProperties)}}{{init.imageSize.$4}}" alt="图片"></a>
            </div>
            <div class="pull-left textInfo" style="width: 44%">
              <strong *ngIf="val.inValid !== 1"><a (click)="productUrl(val.skuCode)" class="a-link">{{val.itemName}}</a></strong>
              <strong *ngIf="val.inValid == 1"><a class="a-link">{{val.itemName}}</a></strong>
              <p>货号：{{val.skuCode}}</p>
              <p>颜色：{{orderService.analysisProductColor(val.skuProperties).valueName}}</p>
              <p>尺码：{{orderService.sizeConvert(orderService.analysisProductSize(val.skuProperties).valueName)}}</p>
              <p style="width: 140%" *ngIf="val.refundId">退货状态：{{orderService.refundStateTranslateArr[val.refundStatus]}}&nbsp;&nbsp;&nbsp;&nbsp;<a [routerLink]="['/my-account/rma-detail']" [queryParams]="{id: val.refundId}" class="a-link-underline">查看详情</a></p>
            </div>
            <div class="pull-right total text-right">
              {{init.currency + val.price}}
              <span>{{val.qty}}件</span>
            </div>
          </div>
          <div class="totalMob text-right">
            订单金额：{{init.currency + cloudOrderResult.payment}}（运费：{{init.currency + cloudOrderResult.postFee}}）
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
<!-- 弹框：加is-select-country is-open -->
<div class="a-overlay js-a-overlay q-opacity-0 q-opacity-95 q-bg-grey-light" [ngClass]="{'is-visible': orderService.show_modal}" data-component="AOverlay" (click)="orderService.closeOverlay()"></div>
<!--物流信息-->
<div data-component="OLightbox" class="o-lightbox is-select-country" [class.is-open]="orderService.showLogistics" style="text-align: center">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix">
    <!-- 弹框 开始 -->
    <div class="m-change-country-lightbox false">
      <div class="expressInfo">
        <div class="expHead" *ngIf="logisticsInfo.length > 0">
          <span class="name">快递公司：{{expressCode}}</span><span>发货单号：{{trackingNumber}}</span>
        </div>
        <div class="expContent">
          <div class="expList" *ngIf="logisticsInfo.length > 0; else noneBlock">
            <ul>
              <li *ngFor="let logistics of logisticsInfo">
                <span>{{logistics.time}}</span>
                <p>{{logistics.context}}</p>
              </li>
            </ul>
          </div>
          <ng-template #noneBlock >
            <div class="expList">
              <ul>
                <li>
                  <p>暂无物流信息</p>
                </li>
              </ul>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <!-- 弹框 结束 -->
    <div class="m-button-icon a-icon-close js-close-button" (click)="orderService.closeOverlay()"></div>
  </div>
</div>
<div data-component="OLightbox" class="o-lightbox is-select-country" [class.is-open]="orderService.showError || orderService.refundApplySuccess" style="text-align: center">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix" *ngIf="orderService.showError">
    <!-- 弹框 开始 -->
    <div class="m-change-country-lightbox false">
      <h2 class="a-heading-2">抱歉！</h2>
      <p class="a-paragraph">{{orderService.message}}</p>
    </div>
    <!-- 弹框 结束 -->
    <div class="m-button-icon a-icon-close js-close-button" (click)="orderService.closeOverlay()"></div>
  </div>

  <div class="lightbox-content js-content u-clearfix" *ngIf="orderService.refundApplySuccess">
    <!-- 弹框 开始 -->
    <div class="m-change-country-lightbox false">
      <p class="a-paragraph refund-mess">{{orderService.message}}</p>
    </div>
    <!-- 弹框 结束 -->
    <div class="m-button-icon a-icon-close js-close-button" (click)="refreshOrder()"></div>
  </div>

</div>
<div data-component="OLightbox" class="o-lightbox is-select-country" [class.is-open]="orderService.canceled || orderService.applyRefund" style="text-align: center">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix">
    <!-- 取消订单 开始 -->
    <div class="m-change-country-lightbox false">
      <h2 class="a-heading-2">您确认取消订单吗？</h2>
      <p class="a-paragraph">&nbsp;</p>
      <a *ngIf="orderService.canceled" class="a-button is-primary" (click)="confirmCancel()">确认取消</a>
      <a *ngIf="orderService.applyRefund" class="a-button is-primary" (click)="confirmRefund(cloudOrderResult)">确认取消</a>
      <button class="a-button is-secondary js-close-button" (click)="orderService.closeOverlay()"><span>再考虑下</span></button>
    </div>
    <!-- 取消订单 结束 -->
  </div>
</div>

<!--我的帐户-更改支付方式-h5 -->
<div data-component="OLightbox" class="o-lightbox is-select-country" [class.is-open]="orderService.changePaymentBox" style="text-align: center; display: block">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix">
    <!-- 取消订单 开始 -->
    <div class="m-change-country-lightbox false">
      <h2 class="a-heading-2">更改支付方式</h2>
      <div data-component="ODelivery" class="o-delivery main-area has-xselected-delivery-option" data-component-id="change_payment">
        <div id="checkoutDeliveryPartForm" class="o-form ng-invalid ng-invalid-required ng-valid-pattern ng-valid-maxlength ng-dirty">
          <div class="m-error js-m-error is-hidden"><label class="a-label js-a-label has-error"></label></div>
          <div data-component="MRadioButtonSelector" class="m-radio-button-selector">
            <div class="m-radio-button u-clearfix u-float-left">
              <input [checked]="orderService.payType == 'wosai:alipay:wap'" type="radio" id="deliveryMode-0" class="a-radio-button" name="delivery-selector2" (click)="setPaymentMethod('wosai:alipay:wap')">
              <label class="a-label js-a-label u-no-select custom-radio" for="deliveryMode-0"></label>
              <label class="a-label js-a-label label-text" for="deliveryMode-0">
                <p class="a-paragraph option ng-bindin icoBg i_alipay">支付宝</p>
              </label>
            </div>
            <div class="m-radio-button u-clearfix u-float-left">
              <input [checked]="orderService.payType == 'wosai:weixin:wap' || orderService.payType == 'wosai:litepos:purchase'" type="radio" id="deliveryMode-1"  class="a-radio-button" name="delivery-selector2" (click)="setPaymentMethod('wosai:weixin:wap')">
              <label class="a-label js-a-label u-no-select custom-radio" for="deliveryMode-1"></label>
              <label class="a-label js-a-label label-text" for="deliveryMode-1">
                <p class="a-paragraph option ng-binding icoBg i_weixin">微信</p>
              </label>
            </div>
          </div>
        </div>
      </div>
      <p class="a-paragraph">&nbsp;</p>
      <a class="a-button is-primary" (click)="modifyPaymentMethod()">确认</a>
      <button class="a-button is-secondary js-close-button" (click)="orderService.closeOverlay()"><span>取消</span></button>
    </div>
    <!-- 取消订单 结束 -->
  </div>
</div>
