<div class="" style="margin-top:0; padding: 0;">
  <div class="relative order-container grid grid-cols-12 gap-20">
    <div class="col-span-10 xs-col-span-12">
      <div class="font_xs_regular pb-40 xxs-pb-30 return-title">申请发票</div>
      <div class="font_xs_regular pt-40  xxs-pt-20 i_title">订单编号</div>
      <div class="mt-8">
        <a [routerLink]="['/my-account/orderdetail']" [queryParams]="{orderid: orderId}" >{{cloudOrderResult?.tid ? cloudOrderResult?.tid : cloudOrderResult?.orderCode}}</a>
      </div>
      <div class="font_xs_regular pt-20 i_title">开票金额</div>
      <div class="mt-8">
        {{init.currency + invoiceAmount}}
      </div>
      <div class="font_xs_regular pt-20 i_title">发票类型</div>
      <div class="mt-8">
        增值税电子普通发票
      </div>
      <div class="pt-40">
        <div class="checkbox-input">
          <div class="m-checkbox u-clearfix">
            <input name="personInvoice" type="radio" class="a-radio-button" id="person_Invoice" [checked]="personInvoice"  (click)="checkInvoiveType('person')">
            <label class="a-label js-a-label u-no-select custom-radio" for="person_Invoice"></label>
            <label class="a-label js-a-label" for="person_Invoice">
              <span class="newsLetterText">个人/事业单位</span>
            </label>
          </div>
          <div class="m-checkbox u-clearfix mt-15">
            <input name="businessInvoice" type="radio" class="a-radio-button" id="business_Invoice" [checked]="businessInvoice"   (click)="checkInvoiveType('business')">
            <label class="a-label js-a-label u-no-select custom-radio" for="business_Invoice"></label>
            <label class="a-label js-a-label" for="business_Invoice">
              <span class="newsLetterText">企业</span>
            </label>
          </div>
        </div>
      </div>
      <div class="pt-40 xxs-pt-20 w-full edit-container">
        <div class="relative w-full">
          <div class="m-input border-b entry-item flex flex-row justify-between items-center">
            <div>
              <label *ngIf="valite.payerName.is_active && valite.payerName.errorTips; else payerNameBlock;" class="a-label js-a-label mb-5 tips">{{payerNameText}}</label>
              <ng-template #payerNameBlock>
                <label class="a-label js-a-label mb-5" *ngIf="invoiceVals.payerName && invoiceVals.payerName.length > 0">发票抬头</label>
              </ng-template>
              <input placeholder="发票抬头" class="p-0 border-none"  name="express_number" [(ngModel)]="invoiceVals.payerName"  (keyup)="staffPayerNameKeyup()" (blur)="stafPayerName()">
            </div>
          </div>
        </div>
        <div class="relative w-full" *ngIf="businessInvoice">
          <div class="m-input border-b entry-item bt-none">
            <label *ngIf="valite.payerRegisterNo.is_active && valite.payerRegisterNo.errorTips; else noBlock;" class="a-label js-a-label mb-5 tips">{{payerRegisterNoText}}</label>
            <ng-template #noBlock>
              <label class="a-label js-a-label mb-5" *ngIf="invoiceVals.payerRegisterNo && invoiceVals.payerRegisterNo.length > 0">税号</label>
            </ng-template>
            <input placeholder="税号" class="p-0 border-none"  name="express_number" [(ngModel)]="invoiceVals.payerRegisterNo"  (keyup)="staffKeyup()" (blur)="stafPayerRegisterNo()">
          </div>
        </div>
        <div class="relative w-full" >
          <div class="m-input border-b entry-item bt-none">
            <label *ngIf="valite.email.errorTips && valite.email.is_active; else emailBlock;" class="a-label js-a-label mb-5 tips">{{emailMessage}}</label>
            <ng-template #emailBlock>
              <label class="a-label js-a-label mb-5" *ngIf="invoiceVals.email && invoiceVals.email.length > 0">邮箱</label>
            </ng-template>
            <input placeholder="邮箱" class="p-0 border-none" type="email"  name="express_number" [(ngModel)]="invoiceVals.email"  (blur)="emailVerify()" >
          </div>
        </div>
        <button [disabled]="saveLock" (click)="onSubmit()" type="button" class="a-button is-primary mt-40 h-50"><span>保存</span></button>
      </div>
    </div>
  </div>
</div>


<!-- 显示加入购物车报错：加is-select-country is-open -->
<div class="a-overlay js-a-overlay q-opacity-0 q-opacity-95 q-bg-grey-light" [ngClass]="{'is-visible': show_modal}"
     data-component="AOverlay" (click)="closeOverlay()" id="productAOverlay"></div>
<div data-component="OLightbox" class="o-lightbox is-select-country" [class.is-open]="show_modal" style="text-align: center">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix">
    <!-- 显示加入购物车报错 开始 -->
    <div class="m-change-country-lightbox false">
      <h2 class="a-heading-2" *ngIf="show_error_message">抱歉！</h2>
      <p class="a-paragraph">{{message}}</p>
    </div>
    <!-- 显示加入购物车报错 结束 -->
    <div class="m-button-icon a-icon-close js-close-button" (click)="closeOverlay()"></div>
  </div>
</div>
