<div class="grid grid-cols-12 gap-x-15 md:gap-x-5 col-span-12 px-0 u-clearfix">
  <div class="col-span-12 lg-col-span-8 o-about-you lg-col-start-2">
    <ng-container *ngIf="canApplyRma; else noRmaCanApplyTpl;">
      <h3 class="font_s_bold return-title">选择退货原因</h3>
      <div class="text-center mt-20">我们非常重视您的反馈意见，以帮助我们改进。</div>
      <div class="mt-20">
        <ng-container *ngFor="let item of rmaOrder?.items; let num = index;">
          <div class="flex mt-20">
            <div class="flex items-center pr-15">
              <input class="a-checkbox hidden" id="ram_check_{{item.orderItemId}}"  type="checkbox" (click)="checkedItem(item)"  [disabled]="item.qty - item.refundQty <1">
              <label class="a-label js-a-label custom-box" for="ram_check_{{item.orderItemId}}"></label>
            </div>
            <div class="w-full relative font_xs_regular" >
              <div class="grid grid-cols-12 md-gap-x-20 item-block">
                <a class="relative col-span-4 aspect-product-portrait sm-col-span-3 md-row-span-2" routerLink="/{{item.frontUrlPath}}">
                  <figure class="absolute bottom-0 left-0 right-0 top-0">
                    <img  class="object-cover transition-opacity duration-300 opacity-100" style="position: absolute;height: 100%;width: 100%;inset: 0px;color: transparent;" src="{{item.hoverUrl ? item.hoverUrl : item.skuImageUrl}}{{init.imageSize.$4}}">
                  </figure>
                </a>
                <div class="col-span-8 pt-16 pl-20 md-pb-15">
                  <a routerLink="/{{item.frontUrlPath}}">
                    <h3 class="truncate font_xs_regular">{{item.itemName}}</h3>
                  </a>
                  <div class="flex justify-between mt-8"><span>{{rmaService.sizeConvert(item.skuProperties[1].valueName)}}</span></div>
                  <div class="flex justify-between mt-8"><span>{{item.skuProperties[0].valueName}}</span></div>
                  <div class="flex justify-between mt-8"><span>数量：{{item.qty}}</span></div>
                  <div class="flex justify-between mt-8"><span>可退数量：{{item.qty - item.refundQty}}</span></div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-20 mb-20 relative w-full" style="padding-left: 29px;" *ngIf="checkedItems.hasOwnProperty(item.orderItemId)">
            <div class="relative w-full">
              <div class="m-input entry-item"  style="padding-bottom: 0 !important;" (click)="openNumList(item)">
                <div class="flex h-full w-full flex-column">
                  <div class="w-full flex flex-row justify-between">
                    <label class="text-left font-size-12" [ngClass]="{'tips': checkedItems[item.orderItemId]['qty_error']}">数量</label>
                    <svg class="inline h-16 w-16 fill-current"><use href="/assets/iconSprite.svg#chevron-mini-down"></use></svg>
                  </div>
                  <span style="color: #080808; margin-top: 5px;">{{checkedItems[item.orderItemId]['qty']}}</span>
                  <!--<input style="padding-left: 0;" type="number" max="{{item.qty}}" min="1" id="numberInput"  class="a-input apply-input" [ngClass]="{'notice':item.qty_error}" [(ngModel)]="checkedItems[item.orderItemId]['qty']" />-->
                </div>
              </div>
              <ng-container *ngIf="checkedItems[item.orderItemId]['maxQty'] > 1">
                <ul class="city-container overflow-auto a-border absolute bt-none" [ngStyle]="{'display':showNumberBox && numItemId==item.orderItemId?'block':'none'}">
                  <li class="w-full" *ngFor="let _check of checkedItems[item.orderItemId]['selectData']">
                    <button (click)="selectNum(item.orderItemId, _check)"  class="w-full px-15 py-16 text-left font_small_xs_regular" type="button">{{_check}}</button>
                  </li>
                </ul>
              </ng-container>
            </div>
            <div class="relative w-full">
              <div class="m-input entry-item bt-none" (click)="openReasonList(item)">
                <div class="flex h-full w-full justify-between">
                  <div class="flex flex-col justify-between">
                    <label class="text-left font-size-12" [ngClass]="{'tips': checkedItems[item.orderItemId]['reason_error']}">原因</label>
                    <span>{{checkedItems[item.orderItemId]['reason']}}</span>
                  </div>
                  <svg class="inline h-16 w-16 fill-current"><use href="/assets/iconSprite.svg#chevron-mini-down"></use></svg>
                </div>
              </div>
              <ul class="city-container overflow-auto a-border absolute bt-none" [ngStyle]="{'display':showBox && orderItemId==item.orderItemId?'block':'none'}">
                <li class="w-full" *ngFor="let reason of refundReason">
                  <button (click)="selectReason(item.orderItemId, reason.reason)"  class="w-full px-15 py-16 text-left font_small_xs_regular" type="button">{{reason.reason}}</button>
                </li>
              </ul>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="submit-btn">
        <button class="a-button is-primary h-50 p-0 m-0" [disabled]="isEmpty()" (click)="onSubmit()">提交</button>
      </div>
    </ng-container>
    <ng-template #noRmaCanApplyTpl>
      <div class="text-center mt-40">
        {{noCanApplyRmaReason}}
      </div>
    </ng-template>
  </div>
</div>

<!-- 弹框：加is-select-country is-open -->
<div class="a-overlay js-a-overlay q-opacity-0 q-opacity-95 q-bg-grey-light" [ngClass]="{'is-visible': show_modal}" data-component="AOverlay" (click)="closeOverlay()"></div>
<div data-component="OLightbox" class="o-lightbox is-select-country" [class.is-open]="show_modal" style="text-align: center">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix">
    <!-- 弹框 开始 -->
    <div class="m-change-country-lightbox false">
      <h2 class="a-heading-2">抱歉！</h2>
      <p class="a-paragraph">{{message}}</p>
    </div>
    <!-- 弹框 结束 -->
    <div class="m-button-icon a-icon-close js-close-button" (click)="closeOverlay()"></div>
  </div>
</div>
