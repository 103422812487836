import {Component, Inject, OnInit, ViewChild, PLATFORM_ID, Renderer2} from '@angular/core';
import {InitializeService} from '../../services/initialize.service';
import {CallComponentService} from '../../services/callcomponent.service';
import {isPlatformBrowser} from '@angular/common';
import {CheckoutService} from '../../services/checkout.service';
import {CheckoutAddressComponent} from '../../components/checkout/checkout-address/checkout-address.component';
import {Angulartics2} from 'angulartics2';
import {Router} from '@angular/router';
import {StorageService} from '../../services/storage.service';
import {CheckoutModel} from '../../models/checkout.model';

@Component({
  selector: 'app-presale-checkout',
  templateUrl: './presale-checkout.component.html',
  styleUrls: ['./presale-checkout.component.css']
})
export class PresaleCheckoutComponent implements OnInit {
  public getCheckoutLoad: any;
  // 接受子组件发来的数据；

  public aliPay!: boolean; // 支付宝支付；
  public weChat!: boolean; // 微信支付；
  public weChatBrowser:boolean = false; //微信浏览器
  public aliPayBrowser:boolean = false;//支付宝客户端
  public checkoutModule:any;
  public timer = null;
  public checkoutType = 'presale';
  // 获取子组件的实例；
  @ViewChild('checkoutadd')
	child!: CheckoutAddressComponent;
  getDataCheckoutPage(event: boolean) {
    this.getCheckoutLoad = event;
  }
  public orderRemark = {'remark': ''};
  constructor(public init: InitializeService,
              @Inject(PLATFORM_ID) private platformId: Object,
              private eLoginService: CallComponentService,
              public router: Router,
              public checkout: CheckoutService,
              private angulartics2: Angulartics2,
              private render: Renderer2,
              private localStorage: StorageService) {
  }

  ngOnInit() {
    this.init.pageConfiguration('结算页', '结算');
    if (this.init.userId) {
      this.eLoginService.loginRegisterBox.next(false);
    }
    if (isPlatformBrowser(this.platformId)) {
      const bodyDom = document.querySelector('body');
      this.render.removeClass(bodyDom, 'u-overflow-hidden');
      this.browserRedirect();
    }
  }
  public browserRedirect() {
    const browsers = {
      versions: function() {
        const u = navigator.userAgent;
        return {     // 移动终端浏览器版本信息
          trident: u.indexOf('Trident') > -1, // IE内核
          presto: u.indexOf('Presto') > -1, // opera内核
          webKit: u.indexOf('AppleWebKit') > -1, // 苹果、谷歌内核
          gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') === -1, // 火狐内核
          mobile: !!u.match(/AppleWebKit.*Mobile.*/) || !!u.match(/AppleWebKit/) && u.indexOf('QIHU') && u.indexOf('Chrome') < 0, // 是否为移动终端
          ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
          android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, // android终端或uc浏览器
          iPhone: u.indexOf('iPhone') > -1 || u.indexOf('Mac') > -1, // 是否为iPhone或者QQHD浏览器
          iPad: u.indexOf('iPad') > -1, // 是否iPad
          webApp: u.indexOf('Safari') === -1,  // 是否web应该程序，没有头部与底部
        };
      }(),
      // language: (navigator.browserLanguage || navigator.language).toLowerCase()
    };
		// 在手机浏览器上打开
    this.checkout.getCheckoutData(this.checkoutType).then( val => {
      this.checkoutModule = val;
      if (this.checkoutModule.checkedItems().length <= 0) {
        this.router.navigate(['/']);
        return;
      }
      // H5端判断
      const ua = navigator.userAgent.toLowerCase();
      const isWx: boolean = (/MicroMessenger/i.test(ua));
      const isAliPay:boolean = (/AlipayClient/i.test(ua));
      this.aliPayBrowser = isAliPay;
      this.weChatBrowser = isWx;
      if(browsers.versions.mobile){
        //微信浏览器
        if(isWx === true){
          if (!this.checkoutModule.payType || this.checkoutModule.payType == 'wosai:weixin:wap') {
            if (this.checkoutModule.payType != 'wosai:litepos:purchase' || this.child.checkoutLoad === false) {
              // H5端微信浏览器中
              this.checkout.setCloudPaymentMethod('wosai:litepos:purchase').then(setRes => {
                if (setRes['code'] == 1000) {
                  this.weChat = true;
                  this.aliPay = false;
                  this.checkoutModule.payType = 'wosai:litepos:purchase';
                }
              });
            }
          }
        }else if(isAliPay === true){	//支付宝浏览器
          if (!this.checkoutModule.payType || this.checkoutModule.payType == 'wosai:alipay:wap') {
            if (this.checkoutModule.payType != 'wosai:alipay:wap' || this.child.checkoutLoad === false) {
              this.checkout.setCloudPaymentMethod('wosai:alipay:wap').then(setRes => {
                if (setRes['code'] == 1000) {
                  this.aliPay = true;
                  this.weChat = false;
                  this.checkoutModule.payType = 'wosai:alipay:wap';
                }
              });
            }
          }
        }else{ //H5浏览器，微信支付方式需根据终端切换
          if (!this.checkoutModule.payType || this.checkoutModule.payType == 'weixin' || this.checkoutModule.payType == 'wosai:weixin:wap') {
            // let localPayType = this.localStorage.getItem('payType') || '';
            // if (!!localPayType && localPayType == 'wosai:weixin:wap') {
            let wxpayname = 'wosai:litepos:purchase';
            this.checkout.setCloudPaymentMethod(wxpayname).then(setRes => {
              if (setRes['code'] == 1000) {
                this.checkoutModule.payType = wxpayname;
                this.weChat = false;
                this.aliPay = false;
              }
            });
            // }
          }
        }
      }else{
        // 在PC端显示
        // this.isPc = true;
        // H5端无订单的情况下
        let localPayType = this.localStorage.getItem('payType') || '';
        if (!this.checkoutModule.payType || this.checkoutModule.payType == 'weixin') {
          console.log("wwwwwwwwwwwwwwwww");
          if (!!localPayType && (localPayType == 'wosai:alipay:wap'
            || localPayType == 'wosai:weixin:wap' || localPayType == 'wosai:litepos:purchase')) {
            this.checkout.setCloudPaymentMethod(localPayType).then(setRes => {
              if (setRes['code'] == 1000) {
                this.checkoutModule.payType = localPayType;
                if (localPayType == 'wosai:alipay:wap') {
                  this.aliPay = true;
                  this.weChat = false;
                } else if (localPayType == 'wosai:weixin:wap' || localPayType == 'wosai:litepos:purchase') {
                  this.weChat = true;
                  this.aliPay = false;
                }
              }
            });
            this.aliPay = true;
            this.weChat = false;
          } else {
            this.checkout.setCloudPaymentMethod('wosai:alipay:wap').then(setRes => {
              if (setRes['code'] == 1000) {
                this.aliPay = true;
                this.weChat = false;
                this.checkoutModule.payType = 'wosai:alipay:wap';
              }
            });
          }
        }
      }
      // 向google 推送结算数据
      if (this.init.gaTrackingFlag) {
        const items = [];
        if (this.checkoutModule.checked_items) {
          for (const i in this.checkoutModule.checked_items) {
            items.push({
              id: this.checkoutModule.checked_items[i].skuCode,
              name: this.checkoutModule.checked_items[i].itemName,
              brand: 'COS',
              price: this.checkoutModule.checked_items[i].price,
              quantity: this.checkoutModule.checked_items[i].qty,
              currency: 'CNY'
            });
          }
        }
        this.angulartics2.eventTrack.next({
          action: 'begin_checkout',
          properties: {
            gstCustom: {
              items: items,
              currency: 'CNY'
            }
          }
        });
      }
    });
  }
}
