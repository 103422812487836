import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {InitializeService} from '../../services/initialize.service';
import {ActivatedRoute, Router} from '@angular/router';
import {RmaService} from '../../services/rma.service';
import {isPlatformBrowser} from '@angular/common';
import $ from 'jquery';

@Component({
  selector: 'app-rma-apply',
  templateUrl: './rma-apply.component.html',
  styleUrls: ['./rma-apply.component.css']
})
export class RmaApplyComponent implements OnInit {
  public rmaOrder :any;
  public products: any = [];
  public checkedItems: any = {};
  public rmaItems:any = [];
  public rma: any = [];

  public canCheckRma: any = false;
  public orderId: any;
  public canApplyRma: any = false;
  public noCanApplyRmaReason: any;
  private submitLock: any = false;
  public show_modal: boolean = false;
  public message: any;
  public refundReason: any = [];
  public defaultReason: any;
  public orderItemId = 0;
  public showBox = false;
  public showNumberBox = false;
  public numItemId = 0;

  constructor(
    private init: InitializeService,
    private router: Router,
    private rouoteInfo: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object,
    public rmaService: RmaService  ) {
  }

  ngOnInit() {
    this.init.pageConfiguration('退货');
    this.rouoteInfo.params.subscribe(parmas => {
      this.orderId = parmas['id'];
      if (isPlatformBrowser(this.platformId)) {
        this.rmaService.cloudOrderDetail(this.orderId).then(data => {
          if (parseInt(data.code, 0) === 1000) {
            this.canApplyRma = true;
            this.rmaOrder = data.data;
            for (const i in this.rmaOrder.items) {
              if (this.rmaOrder.items[i].qty - this.rmaOrder.items[i].refundQty > 0) {
                let _item = this.rmaOrder.items[i];
                _item['hoverUrl'] = '';
                _item['frontUrlPath'] = '';
                // 设置商品属性
                if (typeof _item['skuProperties'] == 'string') {
                  _item['skuProperties'] = JSON.parse(_item['skuProperties']);
                  if(_item['skuProperties'][0].hasOwnProperty('hoverUrl') && _item['skuProperties'][0]['hoverUrl']){
                    _item['hoverUrl'] = _item['skuProperties'][0]['hoverUrl'];
                  }
                  if(_item['skuProperties'][0].hasOwnProperty('frontUrlPath') && _item['skuProperties'][0]['frontUrlPath']){
                    _item['frontUrlPath'] = _item['skuProperties'][0]['frontUrlPath'];
                  }
                }
                this.rmaItems.push(_item);
              }
            }
            this.rmaOrder.items = this.rmaItems;

            if(this.rmaOrder.items.length < 1){
              this.canApplyRma = false;
              this.noCanApplyRmaReason = '暂无可申请退货商品';
            }
            this.showRefundReason();
          } else {
            this.noCanApplyRmaReason = data.message;
            this.canApplyRma = false;
          }
        }).catch(error => error);
      }
    });
  }

  checkedItem(_item: any) {
    const itemId = _item.orderItemId;
    if(this.checkedItems.hasOwnProperty(itemId)){
      delete this.checkedItems[itemId];
    } else {
      const maxQty =  _item.qty - _item.refundQty;
      let selectData = [];
      if(maxQty > 1){
        for(let i=1; i<= maxQty; i++){
          selectData.push(i);
        }
      }
      this.checkedItems[itemId] = {'checked' : true, qty: 1, maxQty: maxQty, reason: '', reason_error: false, qty_error: false, selectData: selectData};
    }

    // const index = this.checkedItems.indexOf(itemId);
    // if (index > -1 ) {
    //   this.checkedItems.splice(index, 1);
    //   for (const i in this.rma) {
    //     if (this.rma[i].orderItemId === itemId) {
    //       this.rma.splice(parseInt(i, 0), 1);
    //     }
    //   }
    //   for (const i in this.rmaOrder.items) {
    //     if (this.rmaOrder.items[i].item_id === itemId) {
    //       this.rmaOrder.items[i].is_exchange = false;
    //       break;
    //     }
    //   }
    //   return;
    // }
    // this.checkedItems.push(itemId);
    // this.rma.push({'orderItemId' : itemId});


    //this.rma.push({'item_id' : itemId, 'qty_requested' : 1});
    //if (productId in this.products) {
    //  this.setItemProduct(itemId, this.products[productId]);
    //  return;
    //}
    //this.rmaService.getRmaOrder(productId, 'product').then(data => {
    //  if (parseInt(data.code, 0) === 200) {
    //    this.products[productId] = data.data;
    //    this.setItemProduct(itemId, this.products[productId]);
    //  }
    //}).catch(error => error);
  }



  setItemProduct(itemId:any, product:any) {
    for (const i in this.rmaOrder.items) {
      if (this.rmaOrder.items[i].item_id === itemId) {
        this.rmaOrder.items[i].product = product;
        break;
      }
    }
    return;
  }

  /*checkedItem(itemId, productId) {
   const index = this.checkedItems.indexOf(itemId);
   if (index > -1 ) {
   this.checkedItems.splice(index, 1);
   for (const i in this.rma) {
   if (this.rma[i].item_id === itemId) {
   this.rma.splice(parseInt(i, 0), 1);
   }
   }
   for (const i in this.rmaOrder.items) {
   if (this.rmaOrder.items[i].item_id === itemId) {
   this.rmaOrder.items[i].is_exchange = false;
   break;
   }
   }
   return;
   }
   this.checkedItems.push(itemId);
   this.rma.push({'item_id' : itemId, 'qty_requested' : 1});
   if (productId in this.products) {
   this.setItemProduct(itemId, this.products[productId]);
   return;
   }
   this.rmaService.getRmaOrder(productId, 'product').then(data => {
   if (parseInt(data.code, 0) === 200) {
   this.products[productId] = data.data;
   this.setItemProduct(itemId, this.products[productId]);
   }
   }).catch(error => error);
   }
   setItemProduct(itemId, product) {
   for (const i in this.rmaOrder.items) {
   if (this.rmaOrder.items[i].item_id === itemId) {
   this.rmaOrder.items[i].product = product;
   break;
   }
   }
   return;
   }*/
  openReasonList(val:any){
    if(this.orderItemId==val.orderItemId){
      this.showBox = !this.showBox;
    } else {
      this.showBox = true;
    }
    this.showNumberBox = false;
    this.numItemId = 0;
    this.orderItemId = val.orderItemId;
  }
  openNumList(val:any){
    if(this.numItemId==val.orderItemId){
      this.showNumberBox = !this.showNumberBox;
    } else {
      this.showNumberBox = true;
    }
    this.showBox = false;
    this.orderItemId = 0;
    this.numItemId = val.orderItemId;
  }
  selectResolution(itemId:any, value:any) {
    for (const i in this.rmaOrder.items) {
      if (this.rmaOrder.items[i].item_id === itemId) {
        // 11代表换货的option_value
        if (parseInt(value, 0) === 11) {
          this.rmaOrder.items[i].is_exchange = true;
        } else {
          this.rmaOrder.items[i].is_exchange = false;
        }
        break;
      }
    }
    for (const j in this.rma) {
      if (this.rma[j].orderItemId === itemId) {
        this.rma[j].resolution = value;
        if (parseInt(value, 0) !== 11) {
          this.rma[j].color_id = undefined;
          this.rma[j].product_id = undefined;
          this.rma[j].simple_product_id = undefined;
        }
      }
    }
    this.onSubmitCheckRma();
    return;
  }
  selectNum(itemId:any, value:any) {
    if(value <= this.checkedItems[itemId]['maxQty']){
      this.checkedItems[itemId]['qty'] = value;
      this.showNumberBox = false;
      this.numItemId = 0;
    }
  }
  selectReason(itemId:any, value:any) {
    this.checkedItems[itemId]['reason'] = value;
    this.defaultReason = value;
    this.showBox = false;
    this.orderItemId = 0;
  }
  colorChange(itemId:any, value:any) {
    for (const i in this.rmaOrder.items) {
      if (this.rmaOrder.items[i].orderItemId === itemId) {
        const product = [];
        for (const j in this.rmaOrder.items[i].product.product_list) {
          if (parseInt(value, 0) === parseInt(this.rmaOrder.items[i].product.product_list[j].color, 0)) {
            product.push(this.rmaOrder.items[i].product.product_list[j]);
          }
        }
        this.rmaOrder.items[i].product_list = product;
        break;
      }
    }
    for (const j in this.rma) {
      if (this.rma[j].orderItemId === itemId) {
        this.rma[j].color_id = value;
      }
    }
    this.onSubmitCheckRma();
    return;
  }
  sizeChange(itemId:any, productId:any, value:any) {
    for (const j in this.rma) {
      if (this.rma[j].orderItemId === itemId) {
        this.rma[j].product_id = productId;
        this.rma[j].simple_product_id = value;
      }
    }
    this.onSubmitCheckRma();
  }
  // qtyChange(itemId:any, value:any) {
  //   this.checkedItems[itemId]['qty'] = value * 1;
  // }
  onSubmitCheckRma() {
    if (!this.canCheckRma) {
      return;
    }
    let errorNum = 0;
    for (const i in this.rma) {
      for (const j in this.rmaOrder.items) {
        if (this.rma[i].orderItemId === this.rmaOrder.items[j].orderItemId) {
          if (!this.rma[i].reason || this.rma[i].reason === '' || this.rma[i].reason === undefined) {
            this.rmaOrder.items[j].reason_error = true;
            errorNum ++;
          } else {
            this.rmaOrder.items[j].reason_error = false;
          }
          if (!this.rma[i].num || this.rma[i].num === 0 || this.rma[i].num === undefined ||
            this.rma[i].num > (this.rmaOrder.items[j].qty - this.rmaOrder.items[j].refundQty) || this.rma[i].num < 1 ||
            !Number.isInteger(this.rma[i].num)
          ) {
            const refundQty = $('#refundQty_'+this.rmaOrder.items[j].orderItemId).val();
            if(refundQty === undefined || refundQty < 1 ){
              this.rmaOrder.items[j].qty_error = true;
              errorNum ++;
            }else{
              this.rma[i].num = refundQty;
            }
          } else {
            this.rmaOrder.items[j].qty_error = false;
          }
        }
      }
    }

    if (errorNum > 0) {
      return false;
    } else {
      return true;
    }
  }
  onSubmit() {
    if (this.submitLock) {
      return;
    } else {
      this.submitLock = true;
    }
    this.canCheckRma = true;

    let errorNum = 0;
    let applyParams:any = [];
    for (const i in this.checkedItems) {
      if(!this.checkedItems[i]['reason']){
        this.checkedItems[i]['reason_error'] = true;
        errorNum ++;
      } else {
        this.checkedItems[i]['reason_error'] = false;
      }
      if (!this.checkedItems[i].qty || this.checkedItems[i].qty === 0 || this.checkedItems[i].qty === undefined ||
        this.checkedItems[i].qty > this.checkedItems[i].maxQty || this.checkedItems[i].qty < 1 ||
        !Number.isInteger(this.checkedItems[i].qty)
      ) {
        this.checkedItems[i]['qty_error'] = true;
        errorNum ++;
      } else {
        this.checkedItems[i]['qty_error'] = false;
      }
    }
    if (errorNum > 0) {
      this.show_modal = true;
      this.message = '请检查您的退货信息是否填写完整。';
      this.submitLock = false;
      return;
    }
    for (const i in this.checkedItems) {
      applyParams.push({
        orderItemId: i, num: this.checkedItems[i].qty, reason: this.checkedItems[i]['reason'],
      });
    }

    const params = {'description':'','goodStatus':'BUYER_RECEIVED','hasGoodsReturn':'1','orderId':this.orderId,'reason':this.defaultReason,'items':applyParams,'refundFee':-1}
    this.rmaService.saveRma(this.orderId, params).then(data => {
      this.submitLock = false;
      if (data.code * 1 === 1000) {
        // this.router.navigate(['/my-account/rma-detail'], {queryParams : {'id' : data.data.refundId}});
        this.router.navigate(['/my-account/rma-list']);
      } else {
        this.show_modal = true;
        this.message = data.msg;
      }
    }).catch(error => error);
  }

  showRefundReason() {
    this.rmaService.refundReason().then(reasons => {
      if(reasons.code === 1000){
        this.refundReason = reasons.data;
      }
    }).catch(error => error);
  }

  isEmpty(){
    if(Object.keys(this.checkedItems).length > 0){
      return false;
    }
    return true;
  }




  // 关闭遮罩层和弹框
  closeOverlay() {
    this.show_modal = false;
  }
}
