
import {Subscription, fromEvent as observableFromEvent} from 'rxjs';

import {filter} from 'rxjs/operators';
import {Component, OnInit, OnDestroy, Inject, PLATFORM_ID} from '@angular/core';
import {InitializeService} from '../../services/initialize.service';
import {CustomerService} from '../../services/customer.service';
import {CartService} from '../../services/cart.service';
import {OrderService} from '../../services/order.service';
import {AddressService} from '../../services/address.service';
import {StorageService} from '../../services/storage.service';
import {Router, NavigationStart} from '@angular/router';
import {isPlatformBrowser} from '@angular/common';
import {NavigationEnd} from '@angular/router';
import $ from 'jquery';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit, OnDestroy {
  public site!: string;
  public currentMenu = '';
  public currentindex = -1;
  public showMbMenu = false;
  public timer : any;
  public bottom_distance = '0px';
  public logoutLogRs:any = {code:'',message:'',data:''};
  /*{'name': '会员成长', path: "/my-account/profile/dev"}*/
  menuLists = {
    'profile': [{'name': '账户信息', path: '/my-account/profile'}, {'name': '收货地址', path: '/my-account/address-book'},{'name':'修改密码', path: '/my-account/update-password'}],
    'coupon': [{'name': '我的优惠', path: '/my-account/coupon'}],
    'order': [{'name': '官网订单', path: '/my-account/order'},{'name': '小程序订单', path: '/my-account/order/mini'},{'name': '退货订单', path: '/my-account/rma-list'}]
  }
  constructor(public init: InitializeService,
              public cart: CartService,
              private orderService: OrderService,
              private customer:CustomerService,
              private address: AddressService,
              private localStorage: StorageService,
              @Inject(PLATFORM_ID) private platformId: Object,
              private router: Router) {
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (!this.init.isLogin()) {
        this.router.navigate(['']);
      }
      this.matchRouter(this.router.url);
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          const currentUrl = this.router.routerState.snapshot.url;
          this.matchRouter(currentUrl);
          if (isPlatformBrowser(this.platformId)) {
            $('html,body').animate({scrollTop: 0}, 500);
          }
        }
      });

      // // 监听滚动
      // if (isPlatformBrowser(this.platformId)) {
      //   observableFromEvent(window, 'scroll').subscribe(() => {
      //     let scrollTop = window.screenY || document.documentElement.scrollTop || document.body.scrollTop;
      //     if (this.init.IsPC()) {
      //       let h = document.documentElement.clientHeight;
      //       let H = document.body.clientHeight;
      //       if (h + scrollTop + 50 > H) {
      //         $('#myaccount-menu').addClass('pc-bottom-out');
      //         $('#myaccount-menu').removeClass('pc-scroll-height');
      //       } else {
      //         $('#myaccount-menu').addClass('pc-scroll-height');
      //         $('#myaccount-menu').removeClass('pc-bottom-out');
      //       }
      //
			// let offsetHeight = document.documentElement.offsetHeight;
			// let scrollHeight = document.documentElement.scrollHeight;
      //
			// if((scrollHeight - offsetHeight) - scrollTop < 10){
			// 	$('#myaccount-menu').addClass('pc-bottom-out-top');
			// }else{
			// 	$('#myaccount-menu').removeClass('pc-bottom-out-top');
			// }
      //       this.bottom_distance = scrollTop + 'px';
      //     } else {
      //       if (scrollTop > 5) {
      //         $('#mobile_navbar').addClass('sp-fixed');
      //       } else {
      //         $('#mobile_navbar').removeClass('sp-fixed');
      //       }
      //     }
      //   });
      // }
    }
  }

  matchRouter(url: string){
    let path = '';
    if(url.includes("?")){
      path = url.split('?')[0]
    } else {
      path = url;
    }
    this.currentindex = -1;
    if(path =='/my-account/profile'){
      this.currentMenu = 'profile';
      this.currentindex = 0;
    }
    // else if(path =='/my-account/profile/dev'){
    //   this.currentMenu = 'profile';
    //   this.currentindex = 1;
    // }
    else if(path =='/my-account/update-password'){
      this.currentMenu = 'profile';
      this.currentindex = 2;
    } else if(path =='/my-account/address-book'){
      this.currentMenu = 'profile';
      this.currentindex = 1;
    } else if(path =='/my-account/coupon'){
      this.currentMenu = 'coupon';
      this.currentindex = 0;
    } else if(path =='/my-account/order' || path =='/my-account/order/orderdetail' || path =='/my-account/logistics' || path =='/my-account/invoice-apply' || path =='/my-account/invoice-detail'){
      this.currentMenu = 'order';
      this.currentindex = 0;
    } else if(path =='/my-account/order/mini' || path =='/my-account/order/mini/orderdetail' || path =='/my-account/mini/invoice-apply' || path =='/my-account/mini/invoice-detail'){
      this.currentMenu = 'order';
      this.currentindex = 1;
    } else if(path =='/my-account/rma-list'|| path =='/my-account/rma-detail' || path =='/my-account/rma-return' || path.includes('/my-account/rma/')){
      this.currentMenu = 'order';
      this.currentindex = 2;
    }
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      // this.subscription.unsubscribe();
    }
  }

  expandNavbar(){
    this.showMbMenu = !this.showMbMenu;
  }

  logout() {
    this.expandNavbar();
    this.insertLogoutLog();
    this.timer = setInterval(() => {
     this.init.logout();
      this.cart.restCartModel();
      this.orderService.orders =  {data: {}, code: '', message: ''};
      this.address.addressListResult = {code: '', message: '', data: {addressList: []}};
      this.router.navigate(['']);
      clearInterval(this.timer);
    }, 500);
  }
  insertLogoutLog(){
    this.customer.clientIpService().then(val=>{
      this.logoutLogRs = val;
      if(this.logoutLogRs.code === 1000){
        const params = {'customerId':this.localStorage.getItem('customer_id'),'ip':this.logoutLogRs.data,'merchantId':this.init.merchantId,'logType':'CUSTOMER_LOGOUT'};
        this.customer.customerLoginLog(params).then( _val => {
        })
      }
    })
  }
}
