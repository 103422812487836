import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {RmaService} from '../../services/rma.service';
import {ActivatedRoute, Router} from '@angular/router';
import {InitializeService} from "../../services/initialize.service";
import {isPlatformBrowser} from '@angular/common';
import {OrderService} from '../../services/order.service';

@Component({
  selector: 'app-rma-account-list-detail',
  templateUrl: './rma-account-list-detail.component.html',
  styleUrls: ['./rma-account-list-detail.component.css']
})
export class RmaAccountListDetailComponent implements OnInit {
  public rmaId:any;
  public rmaDetail: any = {data: {items: {}}};
  constructor(
    private init: InitializeService,
    public rmaService: RmaService,
    private routerInfo: ActivatedRoute,
    public orderService: OrderService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router
  ) { }
  ngOnInit() {
    this.routerInfo.queryParams.subscribe( query => {
      this.init.pageConfiguration('退货订单');
      if (!query.id) {
        this.router.navigate(['']);
      }
      if (isPlatformBrowser(this.platformId)) {
        this.rmaId = query.id;
        this.rmaService.getRma(query.id).then(result => {
          if (result.code * 1 === 1000) {
            this.rmaService.rmaData = result.data;
            this.rmaDetail  = result.data;
            this.rmaService.loading = false;
          } else {
            this.router.navigate(['/my-account/rma-list']);
          }
        }).catch(error => error);
      }
    });
  }


  confirmCancel(){
    this.rmaService.cancelConfirm().then( res => {
      if(res.code === 1000){
        this.rmaService.applyCancel = false;
        this.rmaService.cancelRefundId = '';
        this.rmaService.getRma(this.rmaId).then(result => {
          if (result.code * 1 === 1000) {
            this.rmaService.rmaData = result.data;

			      this.rmaDetail  = result.data;
            this.rmaService.loading = false;
          } else {
          }
        }).catch(error => error);
      }
    }).catch(error => error);
  }

}
