<div class="" style="margin-top:0; padding: 0;">
  <div class="relative order-container grid grid-cols-12 gap-20">
    <div class="col-span-10 xs-col-span-12">
      <div class="font_xs_regular pb-40 xxs-pb-30 return-title">退货</div>
      <div class="font_xs_regular pt-40 pb-40 xxs-pt-20">请填写寄回快递信息：</div>
    </div>
  </div>
  <div class="col-start-1 w-full edit-container">
    <div class="relative w-full">
      <div class="m-input border-b entry-item flex flex-row justify-between items-center" (click)="openExpressList()">
        <div>
          <label *ngIf="express_name_error"  class="a-label js-a-label mb-5 tips">请选择快递公司</label>
          <div class=""><span>{{express_name? express_name : '快递公司'}}</span></div>
        </div>
        <svg class="inline h-16 w-16 fill-current"><use href="/assets/iconSprite.svg#chevron-mini-down"></use></svg>
      </div>
      <ul class="city-container overflow-auto a-border absolute bt-none" [ngStyle]="{'display':showExpress?'block':'none'}">
        <li class="w-full" *ngFor="let express of rmaService.expressNames">
          <button (click)="selectExpressName(express)" class="w-full px-15 py-16 text-left font_small_xs_regular" type="button">{{express.expressName}}</button>
        </li>
      </ul>
    </div>
    <div class="relative w-full" >
      <div class="m-input border-b entry-item bt-none">
        <label *ngIf="express_number_error; else numberBlock;" class="a-label js-a-label mb-5 tips">请填写正确的快递单号</label>
        <ng-template #numberBlock>
          <label class="a-label js-a-label mb-5" *ngIf="express_number && express_number.length > 0">快递单号</label>
        </ng-template>
        <input placeholder="快递单号" class="p-0 border-none"  name="express_number" [(ngModel)]="express_number"  (blur)="numberVerify()">
      </div>
    </div>
    <button (click)="saveExpress()" type="button" class="a-button is-primary mt-40 h-50" [ngClass]="{'btn-disable':saveLock}" tabindex="102"><span>保存</span></button>
  </div>
  <div class="mt-40">
    <a class="a-link a-operate hidden lg-block" routerLink="/"><span class="underline">继续购物</span></a>
    <div class="ramAddress mt-40">
      <strong class="is-black">退货地址：</strong>
      <p>江苏省苏州市昆山市新生路718号，江苏富莱德仓储有限公司物流园一期B幢仓库B-1单元  COS项目组（请不要发到付件和平邮，仓库无法收件）</p>
      <p>邮编：215300</p>
      <p>售后热线：400-015-1365</p>
    </div>
  </div>
</div>
