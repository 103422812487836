import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {InitializeService} from '../../services/initialize.service';
import {isPlatformBrowser} from '@angular/common';
import $ from "jquery";
import {OrderService} from '../../services/order.service';


@Component({
  selector: 'app-invoice-apply',
  templateUrl: './invoice-apply.component.html',
  styleUrls: ['./invoice-apply.component.css']
})

export class InvoiceApplyComponent implements OnInit {
  orderId:any = '';
  public invoiceAmount:any = 0;
  cloudOrderResult: any = {data: {items: {}}};
  public valite:any = { email: { is_active: false, errorTips: false }, payerName: {is_active: false, errorTips: false}, payerRegisterNo: {is_active: false, errorTips: false} };
  public emailMessage:any = '邮箱';
  public payerNameTimer:any = null;
  public payerRegisterNoTimer:any = null;
  public emailTimer:any = null;

  public invoiceVals:any = {email : '', payerName: '', payerRegisterNo: ''};
  public payerNameText = '发票抬头';
  public payerRegisterNoText:any = '税号';
  public businessType = 1;
  public personInvoice = true;
  public businessInvoice = false;
  public saveLock = false;

  public show_modal = false;
  public show_error_message = true;
  public message:any = '';


  constructor(private routerIonfo: ActivatedRoute,
              public init: InitializeService,
              private router: Router,
              public orderService: OrderService,
              @Inject(PLATFORM_ID) private platformId: Object) {
  }
  ngOnInit() {
    this.init.pageConfiguration('申请发票');
    if (this.routerIonfo.snapshot.queryParams['orderid'] != null) {
      this.orderId = this.routerIonfo.snapshot.queryParams['orderid'];
    }
    if (isPlatformBrowser(this.platformId)) {
      this.init.is_loading = true;
      this.getCloudOrderDetail();
    }
  }

  getCloudOrderDetail() {
    this.orderService.getCloudOrder(this.orderId).then( res => {
      if (res.code === 1000) {
        this.cloudOrderResult = res.data;
        if (this.cloudOrderResult.status == 'TRADE_FINISHED' && this.cloudOrderResult.invoiceStatus == 0) {
          this.orderService.getWosaiInvoiceAmount(this.orderId).then( (result:any) => {
            if (result['code'] === 1000) {
              this.invoiceAmount = result['data'].invoiceAmount;
            }
          });
        } else {
          this.router.navigate(['/my-account/order']);
        }
        // 关闭loading
        this.init.is_loading = false;
      }
    }).catch(error => error);
  }

  onSubmit() {
    if (this.saveLock) { return; }
    if (!this.staffPayerNameKeyup() || !this.staffKeyup() || !this.emailVerify()) {
      return false;
    }
    // if (!this.staffKeyup()) {
    //   return false;
    // }
    // if (!this.emailVerify()) {
    //   return false;
    // }

    const params = {
      'businessType': 1,
      'payerEmail': this.invoiceVals.email,
      'payerName': this.invoiceVals.payerName,
      'payerRegisterNo': '',
    };
    if (this.businessInvoice) {
      params.businessType = 2;
      params['payerRegisterNo'] = this.invoiceVals.payerRegisterNo;
    }
    this.saveLock = true;
    this.show_error_message = true;
    this.orderService.wosaiInvoiceBlue(this.orderId, params).then( res => {
      if (res['code'] == 1000) {
        this.show_modal = true;
        this.show_error_message = false;
        this.message = '申请成功';
        this.router.navigate(['/my-account/order']);
      } else if (res['code'] == -1010) {
        this.show_modal = true;
        this.saveLock = false;
        this.message = '开票异常，请联系客服处理';
      } else {
        this.show_modal = true;
        this.saveLock = false;
        this.message = res['msg'];
      }
    });
	return;
  }

  closeOverlay() {
    this.show_modal = false;
    this.message = '';
  }

  checkInvoiveType(type: any) {
    if(type == 'person'){
      this.personInvoice = true;
      this.businessInvoice = false;
    } else {
      this.personInvoice = false;
      this.businessInvoice = true;
    }
  }


  // 判断email
  emailVerify() {
    const reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (this.invoiceVals.email === undefined || this.invoiceVals.email === '') {
      this.emailMessage = '请输入邮箱地址';
      this.valite.email.is_active = true;
      this.valite.email.errorTips = true;
      return false;
    } else {
      this.emailMessage = '邮箱';
      if (!reg.test(this.invoiceVals.email)) {
        this.emailMessage = '请输入有效的电子邮箱地址';
        this.valite.email.is_active = true;
        this.valite.email.errorTips = true;
        return false;
      } else {
        this.emailMessage = '邮箱';
      }
      this.valite.email.is_active = false;
      this.valite.email.errorTips = false;
    }
    return true;
  }

  /*非空判断税号*/
  staffKeyup() {
    if (this.businessInvoice &&  (this.invoiceVals.payerRegisterNo === undefined || this.invoiceVals.payerRegisterNo === null || this.invoiceVals.payerRegisterNo === '')) {
      this.payerRegisterNoText = '请输入税号';
      this.valite.payerRegisterNo.is_active = true;
      this.valite.payerRegisterNo.errorTips = true;
      return false;
    } else {
      this.valite.payerRegisterNo.is_active = false;
      this.valite.payerRegisterNo.errorTips = false;
      this.payerRegisterNoText = '发票税号';
    }
    return true;
  }

  staffPayerNameKeyup() {
    if (this.invoiceVals.payerName === undefined || this.invoiceVals.payerName === null || this.invoiceVals.payerName === '') {
      this.payerNameText = '请输入发票抬头';
      this.valite.payerName.is_active = true;
      this.valite.payerName.errorTips = true;
      return false;
    } else {
      this.valite.payerName.is_active = false;
      this.valite.payerName.errorTips = false;
      this.payerNameText = '发票抬头';
    }
    return true;
  }

  stafPayerRegisterNo() {
    if (this.invoiceVals.payerRegisterNo === undefined || this.invoiceVals.payerRegisterNo === null || this.invoiceVals.payerRegisterNo === '') {
      this.payerRegisterNoText = '请输入税号';
      this.valite.payerRegisterNo.is_active = true;
      this.valite.payerRegisterNo.errorTips = true;
      return false;
    } else {
      this.payerRegisterNoText = '税号';
      this.valite.payerRegisterNo.is_active = false;
      this.valite.payerRegisterNo.errorTips = false;
    }
    return true;
  }

  stafPayerName() {
    if (this.invoiceVals.payerName === undefined || this.invoiceVals.payerName === null || this.invoiceVals.payerName === '') {
      this.payerNameText = '请输入发票抬头';
      this.valite.payerName.is_active = true;
      this.valite.payerName.errorTips = true;
      return false;
    } else {
      this.payerNameText = '发票抬头';
      this.valite.payerName.is_active = false;
      this.valite.payerName.errorTips = false;
    }
    return true;
  }
}
