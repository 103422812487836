import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {InitializeService} from '../../services/initialize.service';
import {RequestService} from '../../services/request.service';
import {ActivatedRoute,Router} from '@angular/router';
import {OrderService} from '../../services/order.service';
import {HttpParams} from '@angular/common/http';
import {isPlatformBrowser} from '@angular/common';
const apiConfig = require('../../config/config.api.json');
import {StorageService} from '../../services/storage.service';
import {ProductService} from '../../services/product.service';

@Component({
  selector: 'app-account-order-detail',
  templateUrl: './account-order-detail.component.html',
  styleUrls: ['./account-order-detail.component.css']
})
export class AccountOrderDetailComponent implements OnInit {
  orderResult: any = {code: '', data: {productList: {}, orderInfo: {}}, message: ''};
  cloudOrderResult: any = {data: {items: {}}};
  orderId:any = '';
  proudctInfo:any = [];
  cloudProductInfo:any = [];
  modifyPaymentOrderId!: string;
  choicePayMent!: string;
  public orderLogistics:any = [];
  public allowConfirm: any = {code: '', data: {}, msg: ''};
  public allowRefund: any = {code: '', data: {}, msg: ''};
  public allow_applay_refund:boolean = false;
  public allow_confirm:boolean = false;
  public trackingNumber: any;
  public expressName: any;
  public logisticsInfo:any = [];
  public expressCode:any = '';
  public applyRefund:any = false;
  public timer:any = '';
  public invoiceAmount:any = 0;
  public weChatBrowser!: boolean;
  public aliPayBrowser!: boolean;
  public mobileBrowser!: boolean;
  constructor(public init: InitializeService,
              private request: RequestService,
              private routerIonfo: ActivatedRoute,
              private router: Router,
              public orderService: OrderService,
              private localStorage: StorageService,
              private productService:ProductService,
              @Inject(PLATFORM_ID) private platformId: Object) {

  }

  ngOnInit() {
    this.init.pageConfiguration('订单详情');
    if (this.routerIonfo.snapshot.queryParams['orderid'] != null) {
      this.orderId = this.routerIonfo.snapshot.queryParams['orderid'];
    }

    const u = navigator.userAgent;
    const ua = navigator.userAgent.toLowerCase();
    this.weChatBrowser = (/MicroMessenger/i.test(ua));
    this.aliPayBrowser = (/AlipayClient/i.test(ua));
    this.mobileBrowser = !!u.match(/AppleWebKit.*Mobile.*/) || !!u.match(/AppleWebKit/) && u.indexOf('QIHU') && u.indexOf('Chrome') < 0 ? true : false;

    if (isPlatformBrowser(this.platformId)) {
      this.init.is_loading = true;
      this.getCloudOrderDetail();
      this.isAllowConfirm(this.orderId);
      // this.isAllowApplyRefund(this.orderId);
    }
  }

  isAllowConfirm(orderId:any){
    this.orderService.isAllowConfirm(orderId).then(
      res => {
        this.allowConfirm = res;
        if(this.allowConfirm.code !== 1000){
        }else{
          if(this.allowConfirm.data.isAllow === '1'){
            this.allow_confirm = true;
          }
        }
      }
    ).catch( error => error);
  }

  productUrl(sku:any){
    this.productService.getProductBySku(sku).then((res: any) => {
      if (parseInt(res.code, 0) !== 200) {
      } else {
        const pdpUrl = '/' + res.data.url_path;
        this.router.navigate([pdpUrl]);
      }
    }).catch(error => error);
  }

  isAllowApplyRefund(orderId:any){
    this.allow_applay_refund = false;
    this.orderService.isAllowApplyRefund(orderId).then(res => {
      this.allowRefund = res;
      if(this.allowRefund.code !== 1000){
      }else{
        if(this.allowRefund.data.isAllow === '1'){
          this.allow_applay_refund = true;
        }
      }
    }).catch(error => error);
  }

  getCloudOrderDetail(){
    this.cloudProductInfo = [];
    this.orderService.getCloudOrder(this.orderId).then( res => {
      if(res.code === 1000){
        this.cloudOrderResult = res.data;
        if(this.cloudOrderResult['status']!='WAIT_BUYER_PAY' && this.cloudOrderResult['status']!='WAIT_SELLER_SEND_GOODS' && this.cloudOrderResult['status']!='TRADE_CLOSED' && this.cloudOrderResult['status']!='TRADE_CLOSED_BY_CLOUD' && this.cloudOrderResult['status']!='BUYER_PART_PAYMENT') {
          this.isAllowApplyRefund(this.orderId);
        }
        const proudctItems = this.cloudOrderResult.items;
        for (const key of Object.keys(proudctItems)) {
          if (proudctItems.hasOwnProperty(key) && proudctItems[key] !== false) {
            const _item = proudctItems[key];
            _item['refundLists'] = [];
            if(_item.refundId){
              this.orderService.getRefundListByOrderItemId(_item['orderItemId']).then((refund_res:any) => {
                if (res.code === 1000) {
                  if(refund_res.data.length > 0){
                    _item['refundLists'] = refund_res.data;
                  }
                }
                this.cloudProductInfo.push(_item);
              });
            } else {
              this.cloudProductInfo.push(_item);
            }
          }
        }
        if (this.cloudOrderResult.status == 'TRADE_FINISHED' && this.cloudOrderResult.invoiceStatus == 0) {
          this.orderService.getWosaiInvoiceAmount(this.orderId).then( (result:any) => {
            if (result['code'] === 1000) {
              this.invoiceAmount = result['data'].invoiceAmount;
            }
          });
        }

        // 关闭loading
        this.init.is_loading = false;
      } else {
        this.router.navigate(['/my-account/order']);
      }
    }).catch(error => error);
  }

  getOrderDetail() {
    this.proudctInfo = [];
    const getOrderInfoUrl = apiConfig.orderListApi + '/' + this.orderId;
    this.request.get(getOrderInfoUrl)
      .subscribe(
        val => {
          this.orderResult = val;
          const proudctInfos = this.orderResult.data.productList;
          for (const key of Object.keys(proudctInfos)) {
            if (proudctInfos.hasOwnProperty(key) && proudctInfos[key] !== false) {
              this.proudctInfo.push(proudctInfos[key]);
            }
          }
          // 关闭loading
          this.init.is_loading = false;
        }
      );
  }

  gopay(orderId:any){
    if(this.localStorage.getItem('h5precreate_'+orderId)){
      this.localStorage.removeItem('h5precreate_'+orderId);
    }
    this.router.navigate(['/checkout/payment/' + orderId]);
  }

  /**
   * 订单物流
   * @param orderId
   */
  logistics(orderId:any){
    this.router.navigate(['/my-account/logistics'], {queryParams : {'orderId' : orderId}});
  }

  /**
   * 打开修改支付方式Box
   * @param orderId
   * @param payType
   */
  changePayment(orderId:any, payType:any) {
    if (!orderId || !payType) {
      return false;
    }
    this.orderService.show_modal = true;
    this.orderService.changePaymentBox = true;
    this.modifyPaymentOrderId = orderId;
    this.orderService.payType = payType;
	return;
  }

  /**
   * 选择支付方式
   * @param payment
   */
  setPaymentMethod(payment:any) {
    if (!payment) {
      return false;
    }
    this.choicePayMent = payment;
	return;
  }

  /**
   * 确认修改支付方式
   * @param value
   */
  modifyPaymentMethod() {
    // 第一次没选择支付方式时候选择默认的
    this.choicePayMent = !!this.choicePayMent ? this.choicePayMent : this.orderService.payType;
	if((this.weChatBrowser || this.mobileBrowser) && !this.aliPayBrowser){
		this.choicePayMent = this.choicePayMent == 'wosai:weixin:wap' ? 'wosai:litepos:purchase' : this.choicePayMent;
	}

	const params = {'payType':this.choicePayMent};
    if (this.orderService.paymentLock) {
      // 更改支付方式加锁中
      this.orderService.paymentLock = false;
      this.orderService.modifyPaymentMethodServer(this.modifyPaymentOrderId,params).then(val => {
        if (val.code === 1000) {
          this.orderService.show_modal = false;
          this.orderService.changePaymentBox = false;
          this.getCloudOrderDetail();
        }
      }).catch( error => error);
    }
  }

  /**
   * 订单列表取消订单
   */
  cancelOrder(order_id:any) {
    this.orderService.show_modal = true;
    this.orderService.canceled = true;
    if (!order_id) {
      this.orderService.orderId = '';
    } else {
      this.orderService.orderId = order_id;
    }
  }

  /**
   * 确认取消订单
   */
  confirmCancel() {
    if (this.orderService.canceledLock) {
      // 取消加锁中
      this.orderService.canceledLock = false;
      if (this.orderService.orderId != null) {
        const params = new HttpParams()
        .set('order_id', this.orderService.orderId);
        this.orderService.cloudOrderCancelServer(params).then(val => {
          if (val.code !== 1000) {
            this.orderService.show_modal = true;
            this.orderService.showError = true;
            this.orderService.message = '出错了，取消失败~';
          }
          this.orderService.show_modal = false;
          this.orderService.canceled = false;
          this.getCloudOrderDetail();
        }).catch( error => error);
      }
    }
  }

  /**
   *  订单确认收货
   * @param orderId
   */
  cloudOrderComplate(orderId:any,orderCode:any) {
    if (this.orderService.complateLock) {
      // 确认加锁
      this.orderId = orderId;
      const cloudOrderCode = orderCode;
      this.orderService.complateLock = false;
      const params = new HttpParams()
        .set('order_code', cloudOrderCode);
      this.orderService.cloudOrderComplateServer(params).then(val => {
        if (val.code !== 1000) {
          this.orderService.show_modal = true;
          this.orderService.showError = true;
          if(val.code === 406){
            this.orderService.message = '订单已支付，请联系客服处理！';
          }else{
            this.orderService.message = '出错了，取消失败~';
          }
        }
        this.allow_confirm = false;
        this.getCloudOrderDetail();
      }).catch( error => error);
    }
  }

  //申请仅退款
  applyJustRefund(){
    this.orderService.show_modal = true;
    this.orderService.applyRefund = true;
  }

  confirmRefund(order:any){
    this.orderService.show_modal = false;
    this.orderService.applyRefund = false;
    let refundItem:any = [];
    order.items.forEach((item:any) => {
      refundItem.push({'num':item.qty,'orderItemId':item.orderItemId,'reason':'未发货取消订单'});
    });

    const params = {'description':'','goodStatus':'BUYER_RECEIVED','hasGoodsReturn':'0','orderId':order.orderId,'reason':'未发货取消订单','items':refundItem,'refundFee':order.payment}
    this.orderService.justRefund(params).then(val => {
      if (val.code !== 1000) {
        this.orderService.show_modal = true;
        this.orderService.showError = true;
        this.orderService.message = '出错了，取消失败~';
        if(val.code == -1004){
          this.orderService.message = val.msg;
        }
      } else {
        this.orderService.show_modal = true;
        this.orderService.refundApplySuccess = true;
        this.orderService.message = '订单已取消，请注意查看退款消息';
      }
    }).catch(error => error);
  }

  refreshOrder(){
    this.orderService.closeOverlay();
    this.getCloudOrderDetail();
  }
}
