<ng-template #elseMiniBlock>
  <div class="o-my-orders-empty mt-40">
    <p class="a-paragraph orders-empty-text">您还没有退货的商品</p>
  </div>
</ng-template>
<ng-container *ngIf="rmaService.rmaData.total > 0; else elseMiniBlock">
  <div><span>退货订单</span></div>
  <div class="relative order-container grid grid-cols-12 gap-20 mt-40 md-mt-30">
    <div class="col-span-10 xs-col-span-12 order-title">
      <div class="grid grid-cols-10 xs-grid-cols-12 gap-20 pt-15 pb-35 xs-pb-25 font_s_medium">
        <div class="col-span-3 xs-col-span-6 pl-20 xs-pl-15">
          <span>服务单号</span>
        </div>
        <div class="col-span-2 xs-col-span-3">
          <span>申请日期</span>
        </div>
        <div class="col-span-1 xxs-hidden">
          <span>数量</span>
        </div>
        <div class="col-span-2 xxs-hidden">
          <span></span>
        </div>
        <div class="col-span-2 xs-col-span-3 pr-20 xs-pr-15 flex justify-end">
          <span>退货状态</span>
        </div>
      </div>
      <ng-container *ngFor="let val of rmaService.rmaData.content; let num = index">
        <section class="grid grid-cols-10 xs-grid-cols-12 gap-x-20 pt-15 pb-35 order-block">
          <div class="col-span-3 xs-col-span-6 xs-pl-12" (click)="showRefundDetail(val)">
            <a class="flex">
              <ng-container *ngIf="showDetail && showRefundId == val.refundId; else otherBlock;">
                <svg class="inline h-16 w-16 fill-current"><use href="/assets/iconSprite.svg#minus-small"></use></svg>
              </ng-container>
              <ng-template #otherBlock>
                <svg class="inline h-16 w-16 fill-current"><use href="/assets/iconSprite.svg#plus-small"></use></svg>
              </ng-template>
              <span class="font_si_xs_medium">{{val.refundCode}}</span>
            </a>
          </div>
          <div class="col-span-2 xs-col-span-3">
            <span class="font_small_xs_regular">{{val.createTime.substr(0,10)}}</span>
          </div>
          <div class="col-span-1 xxs-hidden">
            <span class="font_small_xs_regular">{{val.itemQty}} 件</span>
          </div>
          <div class="col-span-2 xxs-hidden operate-block operate-container">
            <ng-container *ngIf="(val?.status == 'WAIT_SELLER_AGREE' || val?.status == 'WAIT_BUYER_RETURN_GOODS') && val?.reason !== '未发货取消订单'">
              <a class="a-link a-operate ml-10" (click)="rmaService.cancelRefund(val.refundId)"><span class="underline">取消申请</span></a>
            </ng-container>
            <ng-container *ngIf="(!val.expressName || !val.trackingNumber) && val?.status !== 'WAIT_SELLER_AGREE' && val?.status !== 'CLOSED' && val?.status !== 'SUCCESS' && val?.status !== 'RETAIL_STORE_RECEIVED'">
              <a [routerLink]="['/my-account/rma-return']" [queryParams]="{id: val?.refundId}" class="a-link a-operate ml-10" ><span class="underline">填写退货物流信息</span></a>
            </ng-container>
          </div>
          <div class="col-span-2 xs-col-span-3 xs-pr-15">
             <span class="flex items-center font_xs_regular flex justify-end">{{rmaService.refundStateTranslateArr[val.status]}}
               <ng-container *ngIf="val.status=='SUCCESS'; else otherStatusTpl">
                <div style="width: 4px; height: 4px;margin-left: 9px; background-color: #219653;"></div>
               </ng-container>
               <ng-template #otherStatusTpl>
                  <ng-container *ngIf="val.status=='CLOSED'; else otherStatusTpl2">
                    <div style="width: 4px; height: 4px;margin-left: 9px; background-color: red;"></div>
                  </ng-container>
                  <ng-template #otherStatusTpl2>
                    <div style="width: 4px; height: 4px;margin-left: 9px; background-color: #F2994A;"></div>
                  </ng-template>
               </ng-template>
             </span>
          </div>
        </section>
        <ng-container *ngIf="showDetail && showRefundId == val.refundId">
          <div class="w-full order-detail-container">
            <div style="padding: 30px; text-align:center;" *ngIf="showLoading">
              加载中...
            </div>
            <ng-container *ngIf="!showLoading">
              <div class="w-full grid grid-cols-9 xs-grid-cols-12 gap-x-20 relative font_xs_regular">
                <div class="col-span-3 xs-col-span-12 md-pl-20 md-pt-20">
                  <span class="font_s_bold">退货详情</span>
                </div>
                <div class="col-span-3 xs-col-span-12 py-20">
                  <div class="font-size-12">服务单号</div>
                  <div class="mt-10">{{rmaDetail.refundCode}}</div>
                  <div class="font-size-12 mt-20">订单编号</div>
                  <div class="mt-10">{{rmaDetail?.tid ? rmaDetail?.tid : rmaDetail?.orderCode}}</div>
                  <div class="font-size-12 mt-20">申请时间</div>
                  <div class="mt-10">{{rmaDetail.createTime.substr(0,10)}}</div>
                  <ng-container *ngIf="rmaDetail.expressName && rmaDetail.trackingNumber">
                    <div class="font-size-12 mt-20">快递公司</div>
                    <div class="mt-10">{{rmaDetail.expressName}}</div>
                    <div class="font-size-12 mt-20">快递单号</div>
                    <div class="mt-10">{{rmaDetail.trackingNumber}}</div>
                  </ng-container>
                </div>
                <div class="col-span-3 xs-col-span-12 py-20 font_xs_regular pay-block">
                  <div class="font-size-12">处理状态</div>
                  <div class="mt-10">{{rmaService.refundStateTranslateArr[rmaDetail?.status]}}</div>
                  <div class="font-size-12 mt-20">退款金额</div>
                  <div class="mt-10">
                    <ng-container *ngIf="rmaDetail?.notGiftCardFee > 0 && rmaDetail?.giftCardFee > 0 && rmaDetail?.status == 'SUCCESS';else generalPay">
                      <span>{{orderService.orderPaymentTypeArr[rmaDetail.payType]}}</span>￥{{rmaDetail?.notGiftCardFee}}<br/> 礼品卡￥{{rmaDetail?.giftCardFee}}
                    </ng-container>
                    <ng-template #generalPay>
                      <span *ngIf="rmaDetail?.notGiftCardFee > 0">
                        <span>{{orderService.orderPaymentTypeArr[rmaDetail.payType]}}</span>￥{{rmaDetail?.notGiftCardFee}}
                      </span>
                      <ng-container *ngIf="rmaDetail?.giftCardFee > 0">
                        <ng-container *ngIf="rmaDetail?.notGiftCardFee > 0">
                          <br/>
                        </ng-container>
                        <span>礼品卡￥{{rmaDetail?.giftCardFee}}</span>
                      </ng-container>
                    </ng-template>
                  </div>
                  <ng-container *ngIf="rmaDetail?.refundTime && rmaDetail?.refundTime != null">
                    <div class="font-size-12 mt-20">退款时间</div>
                    <div class="mt-10">{{rmaDetail?.refundTime}}</div>
                  </ng-container>
                  <div class="hidden xxs-block font_s_medium operate-block">
                    <ng-container *ngIf="(rmaDetail?.status == 'WAIT_SELLER_AGREE' || rmaDetail?.status == 'WAIT_BUYER_RETURN_GOODS') && rmaDetail?.reason !== '未发货取消订单'">
                      <a class="a-link a-operate mt-20 ml-10" (click)="rmaService.cancelRefund(rmaDetail.refundId)"><span class="underline">取消申请</span></a>
                    </ng-container>
                    <ng-container *ngIf="(!rmaDetail.expressName || !rmaDetail.trackingNumber) && rmaDetail?.status !== 'WAIT_SELLER_AGREE' && rmaDetail?.status !== 'CLOSED' && rmaDetail?.status !== 'SUCCESS' && rmaDetail?.status !== 'RETAIL_STORE_RECEIVED'">
                      <a [routerLink]="['/my-account/rma-return']" [queryParams]="{id: rmaDetail?.refundId}" class="a-link a-operate mt-20 ml-10"><span class="underline">填写退货物流信息</span></a>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="order-container">
                <div class="w-full grid grid-cols-9 xs-grid-cols-12 md-gap-x-20 relative font_xs_regular border-t"  *ngFor="let rmaItem of rmaDetail.items; let num = index">
                  <a routerLink="/{{rmaService.productUrl(rmaItem.skuProperties)}}" class="relative xs-col-span-4 aspect-product-portrait sm-col-span-3 md-row-span-2">
                    <figure class="absolute bottom-0 left-0 right-0 top-0">
                      <img class="object-cover transition-opacity duration-300 opacity-100" style="position: absolute;height: 100%;width: 100%;inset: 0px;color: transparent;" src="{{orderService.analysisProductImage(rmaItem.skuImageUrl, rmaItem.skuProperties)}}{{init.imageSize.$4}}">
                    </figure>
                  </a>
                  <div class="font_xs_regular col-span-6 xs-col-span-8 grid grid-rows-16 gap-8 pr-20 xs-pr-15 py-15 xs-pl-8" style="display: inline-block;">
                    <a class="mb-4 gap-8 flex items-center justify-between w-full" routerLink="/{{rmaService.productUrl(rmaItem.skuProperties)}}">
                      <h3 class="truncate font_xs_regular">{{rmaItem.itemName}}</h3>
                      <!--   <div>
                           <span class="text-right font_small_xs_regular text-main item-price">{{init.formatPrice(_item.price) | currency:currency}}</span>
                         </div>-->
                    </a>
                    <div class="flex justify-between md-mt-35 xs-mt-8"><span>货号</span><span class="font_small_xs_regular">{{rmaItem.skuCode}}</span></div>
                    <div class="flex justify-between mt-8"><span>颜色</span><span>{{rmaService.analysisProductColor(rmaItem.skuProperties).valueName}}</span></div>
                    <div class="flex justify-between mt-8"><span>尺码</span><span class="font_small_xs_regular">{{rmaService.sizeConvert(rmaService.analysisProductSize(rmaItem.skuProperties).valueName)}}</span></div>
                    <div class="flex justify-between mt-8"><span>申请数量</span><span class="font_small_xs_regular">{{rmaItem.num}}</span></div>
                    <div class="flex justify-between mt-8"><span style="white-space: nowrap; padding-right: 20px;">退货原因</span><span class="">{{rmaItem.reason}}</span></div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div *ngIf="rmaService.rmaData.total > 1" class="pagination col-span-9 xs-col-span-12">
      <pagination  [(ngModel)]="rmaService.currentPage" [totalItems]="rmaService.rmaData.total" previousText="上一页" nextText="下一页" [rotate]="false" [maxSize]="5" [itemsPerPage]="rmaService.pageSize" (pageChanged)="handlePaginationEvent($event)"></pagination>
    </div>
  </div>
</ng-container>

<div class="a-overlay js-a-overlay q-opacity-0 q-opacity-95 q-bg-grey-light" [ngClass]="{'is-visible': rmaService.applyCancel}" data-component="AOverlay" (click)="rmaService.closeOverlay()"></div>
<div data-component="OLightbox" class="o-lightbox is-select-country" [class.is-open]="rmaService.applyCancel" style="text-align: center">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix">
    <!-- 取消订单 开始 -->
    <div class="m-change-country-lightbox false">
      <h2 class="a-heading-2">您确认取消退货吗？</h2>
      <p class="a-paragraph">&nbsp;</p>
      <a class="a-button is-primary" (click)="confirmCancel()">确认取消</a>
      <button class="a-button is-secondary js-close-button" (click)="rmaService.closeOverlay()"><span>再考虑下</span></button>
    </div>
    <!-- 取消订单 结束 -->
  </div>
</div>

