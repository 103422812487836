<div [ngClass]="{'logistics-container': orderService.showLogistics}">
  <ng-template #elseMiniBlock>
    <div class="o-my-orders-empty mt-40">
      <p class="a-paragraph orders-empty-text">此订单没有商品</p>
    </div>
  </ng-template>
  <ng-container *ngIf="itemLogistics.length > 0; else elseMiniBlock">
    <div class="relative order-container">
      <div class="grid grid-cols-12 gap-20 xs-gap-x-15 pt-15 pb-35 xs-pb-25 order-title font_s_medium">
        <div class="col-span-3 xs-col-span-6 pl-20 xs-pl-15">
          <span>商品信息</span>
        </div>
        <div class="col-span-2 xs-col-span-2" style="white-space: nowrap;">
          <span>快递公司</span>
        </div>
        <div class="col-span-1 xxs-hidden"  style="white-space: nowrap; margin-left: -20px;">
          <span>单价</span>
        </div>
        <div class="col-span-1 xxs-hidden">
          <span>数量</span>
        </div>
        <div class="col-span-2 xxs-hidden">
          <span></span>
        </div>
        <div class="col-span-3 xs-col-span-4 flex justify-end pr-20 xs-pr-15">
          <span>发货单号</span>
        </div>
      </div>
      <ng-container *ngFor="let val of itemLogistics; let num = index">
        <section class="grid grid-cols-12 gap-x-20 xs-gap-x-15 pt-15 pb-35 order-block">
          <div class="col-span-3 xs-col-span-6" (click)="showOrderDetail(val,num)">
            <a class="flex" style="margin-top: -3px;">
              <div class="flex items-center">
                <ng-container *ngIf="showDetail && showIndex == num; else otherBlock;">
                  <svg class="inline h-16 w-16 fill-current"><use href="/assets/iconSprite.svg#minus-small"></use></svg>
                </ng-container>
                <ng-template #otherBlock>
                  <svg class="inline h-16 w-16 fill-current"><use href="/assets/iconSprite.svg#plus-small"></use></svg>
                </ng-template>
              </div>
              <span class="font_s_medium">{{val.itemName}}</span>
            </a>
          </div>
          <div class="col-span-2 xs-col-span-2" style="white-space: nowrap;">
            <span>{{val.expressName}}</span>
          </div>
          <div class="col-span-1 xxs-hidden" style="white-space: nowrap; margin-left: -20px;">
            <span class="font_small_xs_regular">{{init.currency}}{{val.price | number: '1.2-2'}}</span>
          </div>
          <div class="col-span-1 xxs-hidden">
            <span>{{val.qty}} 件</span>
          </div>
          <div class="col-span-2 xxs-hidden">
            <a class="a-link a-operate" (click)="showLogistics(val)"><span class="underline">查看物流信息</span></a>
          </div>
          <div class="col-span-3 xs-col-span-4 font_si_xs_medium tracking-block flex justify-end xs-pr-15">
            <span class="xxs-hidden">{{val.trackingNumber}}</span>
            <a class="a-link a-operate hidden xxs-block" (click)="showLogistics(val)"><span class="underline">查看物流信息</span></a>
          </div>
        </section>
        <ng-container *ngIf="showDetail && showIndex == num">
          <div>
            <div class="w-full hidden xxs-block item-container">
              <span class="font_s_bold">订单信息</span>
              <div class="mt-30">
                <span class="w-full font-size-12">物流单号</span>
              </div>
              <div class="mt-10">
                <span class="font_small_xs_regular">{{val.trackingNumber}}</span>
              </div>
            </div>
            <div class="w-full grid grid-cols-12 md-gap-x-20 relative font_xs_regular items-block">
              <a class="relative col-span-4 aspect-product-portrait sm-col-span-3 md-row-span-2" routerLink="/{{val['skuProperties'][0].frontUrlPath}}">
                <figure class="absolute bottom-0 left-0 right-0 top-0">
                  <img  class="object-cover transition-opacity duration-300 opacity-100" style="position: absolute;height: 100%;width: 100%;inset: 0px;color: transparent;" src="{{val.hoverUrl ? val.hoverUrl : val.skuImageUrl}}{{init.imageSize.$4}}">
                </figure>
              </a>
              <div class="font_xs_regular col-span-8 grid grid-rows-16 gap-8 sm-col-span-9 pr-20 xs-pr-15 py-15 xs-pl-8" style="display: inline-block;">
                <a class="mb-4 gap-8 flex items-center justify-between w-full" routerLink="/{{val['skuProperties'][0].frontUrlPath}}">
                  <h3 class="truncate font_xs_regular">{{val.itemName}}</h3>
                  <div>
                    <span class="text-right font_small_xs_regular text-main item-price">{{init.formatPrice(val.price) | currency:currency}}</span>
                  </div>
                </a>
                <div class="flex justify-between md-mt-35 xs-mt-8"><span>货号</span><span class="font_small_xs_regular">{{val.skuCode}}</span></div>
                <div class="flex justify-between mt-8"><span>颜色</span><span>{{val.skuProperties[0].valueName}}</span></div>
                <div class="flex justify-between mt-8"><span>尺码</span><span class="font_small_xs_regular">{{orderService.sizeConvert(val.skuProperties[1].valueName)}}</span></div>
                <div class="flex justify-between mt-8"><span>数量</span><span class="font_small_xs_regular">{{val.qty}}</span></div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</div>
<ng-container *ngIf="orderService.showLogistics">
  <div class="express-container relative xxs-hidden">
    <a class="absolute" style="right: 0; top: -15px;" (click)="close()"><svg class="w-16 h-16 inline fill-current"><title>Navigation</title><use href="/assets/iconSprite.svg#nav-close-desktop"></use></svg></a>
    <div class="pb-16 express-title">
      <span>快递公司：{{expressName}}</span>
      <span class="ml-30">发货单号：{{trackingNumber}}</span>
    </div>
    <ng-container *ngIf="logisticsInfo.tracks.length > 0; else noneBlock">
      <div class="expList pr-20">
        <ul>
          <li class="pt-16" *ngFor="let logistics of logisticsInfo.tracks">
            <span>{{logistics['time']}}</span>
            <p>{{logistics['context']}}</p>
          </li>
        </ul>
      </div>
    </ng-container>
    <ng-template #noneBlock>
      <div class="expList pt-16">
        <span>暂无物流信息</span>
      </div>
    </ng-template>
  </div>

  <div class="add_cart_container hidden xxs-flex" style="transform: translateY(0%) translateZ(0px);" (click)="close()">
    <div class="m-cart-addition">
      <div class="w-full content-wrapper u-clearfix cart-wrapper" style="transform: translateY(0%) translateZ(0px);">
        <div class="touch-none">
          <button aria-label="CLOSE" class="flex min-h-13 w-full items-center justify-center cursor-pointer focus:outline-none" type="button">
            <div class="touch-icon"></div>
          </button>
        </div>
        <div class="product-section">
          <div class="heading pl-20 pb-16 pt-24" style="display: block">
            <h2 class="a-heading font_s_medium">物流信息</h2>
          </div>
          <span class="a-icon-close" (click)="close()"></span>
        </div>
        <div class="w-full h5-logistics-header">
          <div class="pl-20">
            <span>快递公司：{{expressName}}</span>
            <span class="ml-30">发货单号：{{trackingNumber}}</span>
          </div>
        </div>

        <ng-container *ngIf="logisticsInfo.tracks.length > 0; else noneBlockH5">
          <div class="list-block px-20 pb-50 h-full" style="overflow: auto">
            <div class="expList pb-50">
              <ul style="padding-bottom: 100px;">
                <li class="pt-16" *ngFor="let logistics of logisticsInfo.tracks">
                  <span>{{logistics['time']}}</span>
                  <p>{{logistics['context']}}</p>
                </li>
              </ul>
            </div>
          </div>
        </ng-container>
        <ng-template #noneBlockH5>
          <div class="expList pt-30" style="text-align: center;">
            <span>暂无物流信息</span>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</ng-container>

