<div>
  <div class="flex flex-row justify-between font_xs_regular">
    <span class="">{{show_pc_order?'官网订单':'小程序订单'}}</span>
    <div class="flex flex-row">
      <div class="myAccount_right w-full">
        <div class="multi_sort pull-right">
          <div data-component="OProductSort" class="o-product-sort date">
            <!-- 激活状态-手机端下拉：is-open -->
            <div class="product-sort-wrapper p-0" (click)="bak($event)" [ngClass]="{'is-open':orderService.showMonthFlag}">
              <div class="hidden xxs-block">
                <a style="display: flex" class="a-link product-sort-link items-center" (click)="orderService.showMonthFlag=true">{{timeCondition}}
                  <svg style="margin-top: -2px;" class="inline h-16 w-16 fill-current"><use href="/assets/iconSprite.svg#chevron-down"></use></svg>
                </a>
              </div>
              <div class="product-sort-section">
                <h2 class="a-heading-2 product-sort-title">下单时间</h2>
                <span class="a-icon-close" (click)="orderService.showMonthFlag=false"></span>
                <div class="product-sort-content">
                  <div class="o-filter p-0">
                    <!-- 激活状态-PC下拉：is-open -->
                    <div data-component="MDropdown" class="m-dropdown filter-dropdown is-filled" [ngClass]="{'is-open':orderService.showMonthFlag}" data-filter-dropdown="true">
                      <label class="a-label js-a-label placeholder ml-30">
                        <span>下单时间：</span><span (click)="clickStatus($event, 'month',orderService.showMonthFlag)">{{orderService.sortMonth}}</span>
                      </label>
                      <svg (click)="clickStatus($event, 'month',orderService.showMonthFlag)" style="margin-top: -2px;color: #080808;" class="inline h-16 w-16 fill-current xxs-hidden"><use href="/assets/iconSprite.svg#chevron-down"></use></svg>
                      <ul class="list-wrapper">
                        <li>
                          <ul class="options list_check" id="list_check_month">
                            <li class="a-option is-selected">
                              <label class="radio_btn" (click)="changeMonthStatus(1)"><span class="option-name font_xs_regular" [ngClass]="{'is-selected':orderService.sortMonth == '1个月'}">1个月</span></label>
                            </li>
                            <li class="a-option">
                              <label class="radio_btn" (click)="changeMonthStatus(3)"><span class="option-name font_xs_regular" [ngClass]="{'is-selected':orderService.sortMonth == '3个月'}">3个月</span></label>
                            </li>
                            <li class="a-option">
                              <label class="radio_btn" (click)="changeMonthStatus(6)"><span class="option-name font_xs_regular" [ngClass]="{'is-selected':orderService.sortMonth == '6个月'}">6个月</span></label>
                            </li>
                            <li class="a-option">
                              <label class="radio_btn" (click)="changeMonthStatus(0)"><span class="option-name font_xs_regular" [ngClass]="{'is-selected':orderService.sortMonth == '查看全部'}">查看全部</span></label>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!---------------------------------------------- 状态区分 ------------------------------------------------------>
          <div data-component="OProductSort" class="o-product-sort state">
            <!-- 激活状态-手机端下拉：is-open -->
            <div class="product-sort-wrapper p-0" (click)="bak($event)" [ngClass]="{'is-open':orderService.showSortFlag}">
              <div class="hidden xxs-block xs-mr-10">
                <a style="display: flex" class="a-link product-sort-link items-center" (click)="orderService.showSortFlag=true">{{statusCondition}}
                  <svg style="margin-top: -2px;" class="inline h-16 w-16 fill-current"><use href="/assets/iconSprite.svg#chevron-down"></use></svg>
                </a>
              </div>
              <div class="product-sort-section">
                <h2 class="a-heading-2 product-sort-title">订单状态：</h2>
                <span class="a-icon-close" (click)="orderService.showSortFlag=false"></span>
                <div class="product-sort-content">
                  <div class="o-filter p-0">
                    <!-- 激活状态：is-open -->
                    <div data-component="MDropdown" class="m-dropdown filter-dropdown is-filled" [ngClass]="{'is-open':orderService.showSortFlag}" data-filter-dropdown="true">
                      <label class="a-label js-a-label placeholder"><span>订单状态：</span>
                        <span (click)="clickStatus($event, 'status', orderService.showSortFlag)">{{orderService.orderStatus}}</span>
                      </label>
                      <span class="a-icon-close"></span>
                      <svg (click)="clickStatus($event, 'status', orderService.showSortFlag)" style="margin-top: -2px; color: #080808;" class="inline h-16 w-16 fill-current xxs-hidden"><use href="/assets/iconSprite.svg#chevron-down"></use></svg>
                      <ul class="list-wrapper">
                        <li>
                          <ul class="options list_check" id="list_check_state">
                            <li class="a-option">
                              <label class="radio_btn" (click)="changeStatus('查看全部')"><span class="option-name font_xs_regular" [ngClass]="{'is-selected' : orderService.orderStatus=='查看全部'}">查看全部</span></label>
                            </li>
                            <li class="a-option">
                              <label class="radio_btn" (click)="changeStatus('待付款')"><span class="option-name font_xs_regular"[ngClass]="{'is-selected' : orderService.orderStatus=='待付款'}">待付款</span></label>
                            </li>
                            <li class="a-option">
                              <label class="radio_btn" (click)="changeStatus('待发货')"><span class="option-name font_xs_regular"[ngClass]="{'is-selected' : orderService.orderStatus=='待发货'}">待发货</span></label>
                            </li>
                            <li class="a-option">
                              <label class="radio_btn" (click)="changeStatus('配送中')"><span class="option-name font_xs_regular" [ngClass]="{'is-selected' : orderService.orderStatus=='配送中'}">配送中</span></label>
                            </li>
                            <li class="a-option">
                              <label class="radio_btn" (click)="changeStatus('已完成')"><span class="option-name font_xs_regular" [ngClass]="{'is-selected' : orderService.orderStatus=='已完成'}">已完成</span></label>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #elseMiniBlock>
  <div class="o-my-orders-empty mt-40">
    <p class="a-paragraph orders-empty-text">您最近一个月没有订单哦，可以点击"下单时间"查看历史订单<br><a href="/" class="a-link">现在就去购物&nbsp;&gt;</a></p>
  </div>
</ng-template>
<ng-container *ngIf="orderListData.content.length > 0; else elseMiniBlock">
  <div class="mt-40 md-mt-30 relative order-container">
    <div class="grid grid-cols-12 gap-20 pt-15 pb-35 xs-pb-25 order-title font_s_medium">
      <div class="col-span-3 xs-col-span-6 pl-20 xs-pl-15">
        <span>订单号</span>
      </div>
      <div class="col-span-2 xs-col-span-3">
        <span>订单日期</span>
      </div>
      <div class="col-span-2 xxs-hidden">
        <span>金额</span>
      </div>
      <div class="col-span-1 xxs-hidden">
        <span>数量</span>
      </div>
      <div class="col-span-2 xxs-hidden">
        <span></span>
      </div>
      <div class="col-span-2 xs-col-span-3 pr-20 xs-pr-15 flex justify-end">
        <span>订单状态</span>
      </div>
    </div>
    <ng-container *ngFor="let val of orderListData.content; let num = index">
      <section class="grid grid-cols-12 gap-x-20 pt-15 pb-35 order-block" >
        <div class="col-span-3 xs-col-span-6 xs-pl-12" (click)="showOrderDetail(val)">
          <a class="flex">
            <ng-container *ngIf="showDetail && showOrderId == val.orderId; else otherBlock;">
              <svg class="inline h-16 w-16 fill-current"><use href="/assets/iconSprite.svg#minus-small"></use></svg>
            </ng-container>
            <ng-template #otherBlock>
              <svg class="inline h-16 w-16 fill-current"><use href="/assets/iconSprite.svg#plus-small"></use></svg>
            </ng-template>
            <span class="font_si_xs_medium">{{val.tid ? val.tid : val.orderCode}}</span>
          </a>
        </div>
        <div class="col-span-2 xs-col-span-3">
          <span class="font_small_xs_regular">{{val.orderTime.substr(0,10)}}</span>
        </div>
        <div class="col-span-2 xxs-hidden">
          <span class="font_small_xs_regular">{{init.currency}}{{val.payment | number: '1.2-2'}}</span>
        </div>
        <div class="col-span-1 xxs-hidden">
          <span class="font_small_xs_regular">{{val.itemQty}}件</span>
        </div>
        <div class="col-span-2 operate-block operate-container xxs-hidden">
          <ng-container *ngTemplateOutlet="myTemplate; context: { oItem: val }"></ng-container>
        </div>
        <div class="col-span-2 xs-col-span-3 xs-pr-15 flex flex-column">
          <span class="flex items-center justify-end">{{orderService.orderStateTranslateArr[val.status]}}
            <ng-container *ngIf="val.status=='TRADE_FINISHED'; else otherStatusTpl">
              <div style="width: 4px; height: 4px;margin-left: 9px; background-color: #219653;"></div>
            </ng-container>
            <ng-template #otherStatusTpl>
               <ng-container *ngIf="val.status=='closed' || val.status=='canceled' || val.status=='TRADE_CLOSED' || val.status=='TRADE_CLOSED_BY_CLOUD' || val.status=='DC_RECEIVED' || val.status=='RETAIL_STORE_RECEIVED'; else otherStatusTpl2">
                 <div style="width: 4px; height: 4px;margin-left: 9px; background-color: red;"></div>
               </ng-container>
               <ng-template #otherStatusTpl2>
                   <div style="width: 4px; height: 4px;margin-left: 9px; background-color: #F2994A;"></div>
               </ng-template>
            </ng-template>
          </span>
          <span *ngIf="val.status === 'WAIT_BUYER_PAY' && val.storeId === 8" class="mt-10 flex justify-end pay-mini">请到小程序支付</span>
        </div>
      </section>
      <ng-template #myTemplate let-oItem="oItem">
        <ng-container *ngIf="oItem.orderType == 'normal';else presalePayTpl">
          <ng-container *ngIf="oItem.status === 'WAIT_BUYER_PAY'">
            <a class="a-link a-operate" *ngIf="oItem.storeId === 10" (click)="gopay(oItem.orderId)"><span class="underline">付款</span></a>
            <a class="a-link a-operate" (click)="cancelOrder(oItem.orderId)"><span class="underline">取消订单</span></a>
          </ng-container>
        </ng-container>
        <ng-template #presalePayTpl>
          <a class="a-link a-operate" *ngIf="oItem.status === 'WAIT_BUYER_PAY' && oItem.storeId === 10" (click)="gopay(oItem.orderId)"><span class="underline">支付定金</span></a>
          <a class="a-link a-operate" *ngIf="oItem.status === 'BUYER_PART_PAYMENT' && oItem.storeId === 10 && init.balancePaymentStatus(oItem.presaleJson) == '尾款支付已开始'" (click)="gopay(oItem.orderId)"><span class="underline">支付尾款</span></a>
          <a class="a-link a-operate" *ngIf="oItem.status === 'WAIT_BUYER_PAY'" (click)="cancelOrder(oItem.orderId)" href="javascript:void(0)"><span class="underline">取消订单</span></a>
        </ng-template>
        <!--已支付订单2小时内可取消订单-->
        <a class="a-link a-operate"  (click)="applyJustRefund(val)" *ngIf="orderService.allowJustRefund(val)"><span class="underline">取消订单</span></a>
        <a *ngIf="oItem.allow_confirm" class="a-link a-operate" (click)="cloudOrderComplate(oItem.orderId,oItem.orderCode)"><span class="underline">确认收货</span></a>
        <a *ngIf="(oItem.status === 'WAIT_BUYER_CONFIRM_GOODS' || oItem.status === 'TRADE_FINISHED') && oItem.shipTime !== null" class="a-link a-operate" (click)="logistics(oItem.orderId)"><span class="underline">查看物流</span></a>
        <a *ngIf="oItem.allow_applay_refund" class="a-link a-operate" routerLink="/my-account/rma/{{oItem.orderId}}"><span class="underline">申请退货</span></a>
        <a *ngIf="oItem.status == 'TRADE_FINISHED' && oItem.invoiceStatus == 0 && oItem.invoiceAmount > 0" class="a-link a-operate"  [routerLink]="[show_pc_order?'/my-account/invoice-apply':'/my-account/mini/invoice-apply']" [queryParams]="{orderid: oItem.orderId}"><span class="underline">申请发票</span></a>
        <ng-container *ngIf="oItem.status == 'TRADE_FINISHED' && oItem.invoiceStatus == 2 || oItem.status == 'TRADE_FINISHED' && oItem.invoiceStatus == 3 || oItem.invoiceStatus == 5">
          <a [routerLink]="[show_pc_order?'/my-account/invoice-detail':'/my-account/mini/invoice-detail']" [queryParams]="{orderid: oItem.orderId}"  class="a-link a-operate"><span class="underline">查看发票</span></a>
        </ng-container>
      </ng-template>
      <ng-container *ngIf="showDetail && showOrderId == val.orderId">
        <div class="w-full order-detail-container">
          <div style="padding: 30px; text-align:center;" *ngIf="showLoading">
            加载中...
          </div>
          <ng-container *ngIf="!showLoading">
            <div class="w-full grid grid-cols-12 gap-x-20 relative font_xs_regular">
              <div class="col-span-3 xs-col-span-12 md-pl-20 md-pt-20">
                <span class="font_s_bold">订单详情</span>
              </div>
              <div class="col-span-4 xs-col-span-12 xs-row-start-3 py-20">
                <div class="font-size-12">收货人</div>
                <div class="mt-10">{{cloudOrderResult.receiverName}}</div>
                <div class="font-size-12 mt-20">手机</div>
                <div class="mt-10">{{cloudOrderResult.receiverMobile}}</div>
                <div class="font-size-12 mt-20">地址</div>
                <div class="mt-10 address-block">{{cloudOrderResult.receiverState}}-{{cloudOrderResult.receiverCity}}-{{cloudOrderResult.receiverDistrict}}-{{cloudOrderResult.receiverAddress}}</div>
                <div class="xxs-hidden">
                  <ng-container *ngIf="cloudOrderResult.orderType != 'step'; else orderPayType;">
                  </ng-container>
                </div>
              </div>
              <div class="col-span-4 xs-col-span-12 xs-col-start-1 xs-row-start-2 py-20 font_xs_regular pay-block">
                <div class="font-size-12">下单时间</div>
                <div class="mt-10">{{cloudOrderResult.orderTime.substr(0,10)}}</div>
                <div class="hidden xxs-block">
                  <ng-container *ngIf="cloudOrderResult.orderType != 'step'; else orderPayType;">

                  </ng-container>
                </div>
                <div class="font-size-12 mt-20">订单状态</div>
                <div class="mt-10" *ngIf="cloudOrderResult.orderType != 'step'">{{orderService.orderStateTranslateArr[cloudOrderResult.status]}}</div>
                <!--预售信息-->
                <ng-container *ngIf="cloudOrderResult.orderType == 'step'; else orderPayType;">
                  <ng-container *ngIf="cloudOrderResult.status == 'WAIT_BUYER_PAY'">
                    <div class="mt-10 presale">定金待支付</div>
                    <div class="mt-10 ">
                      订单尾款支付时间为<span class="presale">{{init.getBalanceStartTime(cloudOrderResult.presaleJson)}}至{{init.getBalanceEndTime(cloudOrderResult.presaleJson)}}</span>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="init.balancePaymentStatus(cloudOrderResult.presaleJson) == '尾款支付尚未开始' && cloudOrderResult.status == 'BUYER_PART_PAYMENT'">
                    <div class="mt-10 presale">尾款支付尚未开始</div>
                    <div class="mt-10 presale">{{init.getBalanceStartTime(cloudOrderResult.presaleJson)}}开始支付尾款</div>
                    <div>请关注COS官方公众号，以便在可支付尾款时收到我们的消息提醒</div>
                  </ng-container>
                  <ng-container *ngIf="init.balancePaymentStatus(cloudOrderResult.presaleJson) == '尾款支付已开始' && cloudOrderResult.status == 'BUYER_PART_PAYMENT'">
                    <div class="mt-10 presale">尾款支付已开始</div>
                    <div class="mt-10 presale">
                      尾款支付截止时间{{init.getBalanceEndTime(cloudOrderResult.presaleJson)}}. 预售商品预计在尾款支付后的48小时内发货
                    </div>
                  </ng-container>
                  <ng-container *ngIf="init.balancePaymentStatus(cloudOrderResult.presaleJson) == '尾款支付已关闭'">
                    <div class="mt-10 presale">尾款支付已关闭</div>
                    <div class="mt-10 presale">{{init.getBalanceEndTime(cloudOrderResult.presaleJson)}}尾款支付截止</div>
                  </ng-container>
                  <ng-container *ngIf="cloudOrderResult.status == 'WAIT_SELLER_SEND_GOODS'">
                    <div class="mt-10">待发货</div>
                  </ng-container>
                  <ng-container *ngIf="cloudOrderResult.status == 'WAIT_SELLER_SEND_GOODS' || cloudOrderResult.status == 'BUYER_PART_PAYMENT' || cloudOrderResult.status == 'WAIT_BUYER_PAY'">
                    <div class="mt-10 pt-12 px-15 presale-pay-block">
                      <div class="flex justify-between">
                        <span *ngIf="cloudOrderResult.status == 'WAIT_BUYER_PAY'; else otherTpl">定金（待支付）</span>
                        <ng-template #otherTpl>
                          <span>定金（已支付）</span>
                        </ng-template>
                        <span>¥&nbsp;{{cloudOrderResult.advancePayment}}</span>
                      </div>
                      <div class="flex justify-between mt-12 presale">
                        <span>优惠</span>
                        <span>¥&nbsp;{{cloudOrderResult.discountFee}}</span>
                      </div>
                      <div class="flex justify-between mt-12">
                        <ng-container *ngIf="init.balancePaymentStatus(cloudOrderResult.presaleJson) == '尾款支付已关闭';else availableTpl">
                          <span>尾款（已关闭）</span>
                        </ng-container>
                        <ng-template #availableTpl>
                          <ng-container *ngIf="cloudOrderResult.status == 'WAIT_SELLER_SEND_GOODS';else othersTpl">
                            <span>尾款（已支付）</span>
                          </ng-container>
                          <ng-template #othersTpl>
                            <span>尾款</span>
                          </ng-template>
                        </ng-template>
                        <span>¥&nbsp;{{cloudOrderResult.balancePayment}}</span>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="cloudOrderResult.status != 'WAIT_BUYER_PAY' && cloudOrderResult.status != 'BUYER_PART_PAYMENT' && cloudOrderResult.status != 'WAIT_SELLER_SEND_GOODS'  &&  init.balancePaymentStatus(cloudOrderResult.presaleJson) != '尾款支付已关闭'">
                    <div class="mt-10">{{orderService.orderStateTranslateArr[cloudOrderResult.status]}}</div>
                  </ng-container>
                </ng-container>
                <div class="hidden xxs-flex font_s_medium mt-20 operate-block">
                  <ng-container *ngTemplateOutlet="myTemplate; context: { oItem: cloudOrderResult }"></ng-container>
                </div>
              </div>
              <ng-template #orderPayType>
                <div class="font-size-12 mt-20">支付方式</div>
                <div class="mt-10 flex items-center">
                  <!--多种支付方式金额-->
                  <ng-container *ngIf="cloudOrderResult.giftCardFee > 0 && cloudOrderResult.notGiftCardFee > 0;else generalPayTpl">
                    <span>微信支付</span><span class="ml-8">{{cloudOrderResult.notGiftCardFee !== undefined ? "¥ " +cloudOrderResult.notGiftCardFee : 0}}</span>
                    <span class="ml-8">礼品卡支付</span><span class="ml-8">{{cloudOrderResult.giftCardFee !== undefined ? "¥ " +cloudOrderResult.giftCardFee : 0}}</span>
                    <ng-container *ngIf="cloudOrderResult.status === 'WAIT_BUYER_PAY' && cloudOrderResult.storeId === 10">
                      <span class="font-size-12" style="height: 14px;margin-bottom: 3px; border-left: 1px solid #080808;margin-left: 8px;padding-right: 8px;"></span>
                      <a class="a-link" (click)="changePayment(cloudOrderResult.orderId,cloudOrderResult.payType)"><span class="underline">更改</span></a>
                    </ng-container>
                  </ng-container>
                  <ng-template #generalPayTpl>
                    <span>{{orderService.orderPaymentTypeArr[cloudOrderResult.payType]}}</span>
                    <ng-container *ngIf="cloudOrderResult.status === 'WAIT_BUYER_PAY' && cloudOrderResult.storeId === 10">
                      <span class="font-size-12" style="height: 14px;margin-bottom: 3px; border-left: 1px solid #080808; margin-left: 8px;padding-right: 8px;"></span>
                      <a class="a-link" (click)="changePayment(cloudOrderResult.orderId,cloudOrderResult.payType)"><span class="underline">更改</span></a>
                    </ng-container>
                  </ng-template>
                </div>
              </ng-template>
            </div>
            <div class="order-container">
              <div class="w-full grid grid-cols-12 md-gap-x-20 relative font_xs_regular border-t"  *ngFor="let _item of cloudProductInfo; let num = index">
                <a class="relative col-span-4 aspect-product-portrait sm-col-span-3 md-row-span-2" (click)="productUrl(_item.skuCode, _item.inValid)">
                  <figure class="absolute bottom-0 left-0 right-0 top-0">
                    <img class="object-cover transition-opacity duration-300 opacity-100" style="position: absolute;height: 100%;width: 100%;inset: 0px;color: transparent;" src="{{_item.hoverUrl ? _item.hoverUrl : _item.skuImageUrl}}{{init.imageSize.$4}}">
                  </figure>
                </a>
                <div class="font_xs_regular col-span-8 grid grid-rows-16 gap-8 sm-col-span-9 pr-20 xs-pr-15 py-15 xs-pl-8" style="display: inline-block;">
                  <a class="mb-4 gap-8 flex items-center justify-between w-full" (click)="productUrl(_item.skuCode, _item.inValid)">
                    <h3 class="truncate font_xs_regular">{{_item.itemName}}</h3>
                    <div class="xxs-hidden">
                      <span class="text-right font_small_xs_regular text-main item-price">{{init.formatPrice(_item.price) | currency:currency}}</span>
                    </div>
                  </a>
                  <div class="md-mt-35 xs-mt-8 hidden xxs-flex justify-between"><span>价格</span><span class="font_small_xs_regular">{{init.formatPrice(_item.price) | currency:currency}}</span></div>
                  <div class="flex justify-between md-mt-35 xs-mt-8"><span>货号</span><span class="font_small_xs_regular">{{_item.skuCode}}</span></div>
                  <div class="flex justify-between mt-8"><span>颜色</span><span>{{orderService.analysisProductColor(_item.skuProperties).valueName}}</span></div>
                  <div class="flex justify-between mt-8"><span>尺码</span><span class="font_small_xs_regular">{{orderService.sizeConvert(orderService.analysisProductSize(_item.skuProperties).valueName)}}</span></div>
                  <div class="flex justify-between mt-8"><span>数量</span><span class="font_small_xs_regular">{{_item.qty}}</span></div>
                  <ng-container *ngIf="_item['refundLists'].length > 0">
                    <ng-container *ngFor="let _refundVal of _item['refundLists']">
                      <div *ngIf="_refundVal.status !== 'CLOSED'" class="flex justify-between mt-8"><span>退货状态</span><span class="font_small_xs_regular">{{orderService.refundStateTranslateArr[_refundVal.status]}}<a class="a-link ml-10" [routerLink]="['/my-account/rma-detail']" [queryParams]="{id: _refundVal.refundId}"><span class="underline">查看详情</span></a></span></div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
              <div class="w-full grid grid-cols-12 gap-x-20 relative font_xs_regular border-t">
                <div class="col-start-4 xs-col-start-1 col-end-13 md-pr-20 xs-pl-15 xs-pr-15">
                  <div class="py-20">
                    <div class="flex justify-between"><span>运费</span><span class="font_small_xs_regular">{{init.currency}}{{cloudOrderResult?.postFee | number: '1.2-2'}}</span></div>
                  </div>
                  <div class="md-pt-20 pb-20 xs-pb-20 md-border-t" >
                    <div class="flex justify-between"><span>总计</span><span class="font_si_xs_bold">{{init.currency}}{{cloudOrderResult?.payment | number: '1.2-2'}}</span></div>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="(cloudOrderResult.addition !== null && cloudOrderResult.addition !== '' && cloudOrderResult.addition.hasOwnProperty('shipmentQty')) && cloudOrderResult.addition.shipmentQty > 1">
                <div class="w-full pt-15 text-center border-t tips-block" >
                  <div>该订单分包裹发送，请注意查收</div>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div *ngIf="orderListData.total > 0;" class="pagination">
    <pagination  [(ngModel)]="orderService.currentPage" [totalItems]="orderListData.total" previousText="上一页" nextText="下一页" [rotate]="false" [maxSize]="5" [itemsPerPage]="orderService.pageSize" (pageChanged)="handlePaginationEvent($event)"></pagination>
  </div>
</ng-container>

<!-- 弹框：加is-select-country is-open -->
<div class="a-overlay js-a-overlay q-opacity-0 q-opacity-95 q-bg-grey-light" [ngClass]="{'is-visible': orderService.show_modal}" data-component="AOverlay" (click)="orderService.closeOverlay()"></div>
<div data-component="OLightbox" class="o-lightbox is-select-country" [class.is-open]="orderService.showError || orderService.refundApplySuccess" style="text-align: center">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix" *ngIf="orderService.showError">
    <!-- 弹框 开始 -->
    <div class="m-change-country-lightbox false">
      <h2 class="a-heading-2">抱歉！</h2>
      <p class="a-paragraph">{{orderService.message}}</p>
    </div>
    <!-- 弹框 结束 -->
    <div class="m-button-icon a-icon-close js-close-button" (click)="orderService.closeOverlay()"></div>
  </div>

  <div class="lightbox-content js-content u-clearfix" *ngIf="orderService.refundApplySuccess">
    <!-- 弹框 开始 -->
    <div class="m-change-country-lightbox false">
      <p class="a-paragraph">{{orderService.message}}</p>
    </div>
    <!-- 弹框 结束 -->
    <div class="m-button-icon a-icon-close js-close-button" (click)="refreshOrderList()"></div>
  </div>
</div>
<div data-component="OLightbox" class="o-lightbox is-select-country" [class.is-open]="orderService.canceled || orderService.applyRefund" style="text-align: center">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix">
    <!-- 取消订单 开始 -->
    <div class="m-change-country-lightbox false">
      <h2 class="a-heading-2">您确认取消订单吗？</h2>
      <p class="a-paragraph">&nbsp;</p>
      <a *ngIf="orderService.canceled" class="a-button is-primary" (click)="confirmCancel()">确认取消</a>
      <a *ngIf="orderService.applyRefund" class="a-button is-primary" (click)="confirmRefund()">确认取消</a>
      <button class="a-button is-secondary js-close-button" (click)="orderService.closeOverlay()"><span>再考虑下</span></button>
    </div>
    <!-- 取消订单 结束 -->
  </div>
</div>
<!--我的帐户-更改支付方式-h5 -->
<div data-component="OLightbox" class="o-lightbox is-select-country" [class.is-open]="orderService.changePaymentBox" style="text-align: center; display: block">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix">
    <!-- 取消订单 开始 -->
    <div class="m-change-country-lightbox false">
      <h2 class="a-heading-2">更改支付方式</h2>
      <div data-component="ODelivery" class="o-delivery main-area has-xselected-delivery-option" data-component-id="change_payment">
        <div id="checkoutDeliveryPartForm" class="o-form ng-invalid ng-invalid-required ng-valid-pattern ng-valid-maxlength ng-dirty">
          <div class="m-error js-m-error is-hidden"><label class="a-label js-a-label has-error"></label></div>
          <div data-component="MRadioButtonSelector" class="m-radio-button-selector">
            <div class="m-radio-button u-clearfix u-float-left">
              <input [checked]="orderService.payType == 'alipay' || orderService.payType == 'wosai:alipay:wap'" type="radio" id="deliveryMode-0" class="a-radio-button" name="delivery-selector2" (click)="setPaymentMethod('wosai:alipay:wap')">
              <label class="a-label js-a-label u-no-select custom-radio" for="deliveryMode-0"></label>
              <label class="a-label js-a-label label-text" for="deliveryMode-0">
                <p style="padding-left: 27px; padding-top: 0;" class="a-paragraph option ng-bindin icoBg i_alipay">支付宝</p>
              </label>
            </div>
            <div class="m-radio-button u-clearfix u-float-left">
              <input [checked]="orderService.payType == 'weixin' || orderService.payType == 'wosai:weixin:wap'" type="radio" id="deliveryMode-1"  class="a-radio-button" name="delivery-selector2" (click)="setPaymentMethod('wosai:weixin:wap')">
              <label class="a-label js-a-label u-no-select custom-radio" for="deliveryMode-1"></label>
              <label class="a-label js-a-label label-text" for="deliveryMode-1">
                <p style="padding-left: 27px; padding-top: 0;" class="a-paragraph option ng-binding icoBg i_weixin">微信</p>
              </label>
            </div>
          </div>
        </div>
      </div>
      <p class="a-paragraph">&nbsp;</p>
      <a class="a-button is-primary" (click)="modifyPaymentMethod()">确认</a>
      <button class="a-button is-secondary js-close-button" (click)="orderService.closeOverlay()"><span>取消</span></button>
    </div>
    <!-- 取消订单 结束 -->
  </div>
</div>
