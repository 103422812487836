<div class="">
  <div>
    <!--<div class="topSection clearfix" style=" float:left;" *ngIf="!emptyFavoriteText">
      <h5 class="pull-left">已收藏{{favoriteTotal}}件商品</h5>
    </div>-->
    <!--模式切换 暂隐  style="display: none;" -->
   <!-- <div class="my-orders view"><span  class="a-link outfit model heading" [ngClass]="{'is-selected': !showHoverImage}" (click)="switchImageMode(1)">模特图</span>/<span (click)="switchImageMode(2)" class="a-link outfit tile" [ngClass]="{'is-selected': showHoverImage}">平铺图</span></div>-->
    <div class="clearfix"></div>
    <nav class="col-span-full relative flex w-full gap-x-20 items-center justify-start lg-justify-center font_s_regular px-15 py36 lg-pt-20 md-px-20">
      <a routerLink="/cart"  class="">购物袋（ {{getCartModel()?.total_qty_ordered>0?getCartModel()?.total_qty_ordered: 0}} ）</a>
      <a class="font_small_l_bold">心愿单（ {{favoriteTotal}} ）</a>
    </nav>
    <div class="hidden top-box px-15 md-px-20">
      <span class="font_small_xxs_medium">已添加{{favoriteTotal}}件商品到心愿单</span>
    </div>
    <div *ngIf="!emptyFavoriteText">
      <div class="grid-cols-12 gap-x-15 md-gap-x-20 col-span-12 flex flex-col lg-grid lg-gap-20 lg-row-gap-50 lg-pt-34 w-full">
        <div class="favorites-list-product-block relative lg-col-span-3 [&amp;_.details]:lg:pl-5" role="button" tabindex="-1" *ngFor="let item of favoriteList; let i = index;">
          <button aria-label="Remove from wishlist" class="absolute cancelH hidden lg-block" type="button" (click)="delFavoriteItem(item.favoritesId,item)">
            <svg class="inline h-16 w-16 fill-current"><title>Close</title>
              <use href="/assets/iconSprite.svg#nav-close-desktop"></use>
            </svg>
          </button>
          <div class="grid w-full grid-cols-12 lg-flex lg-flex-col lg-gap-6">
            <div class=" relative aspect-product-portrait shrink-0 col-span-4 sm-col-span-3 lg-w-full">
              <a class="group"  routerLink="/{{item.frontUrlPath}}">
                <!--辅图-->
                <figure class="absolute bottom-0 left-0 right-0 top-0 object-cover opacity-0 group-hover-opacity-100">
                  <img class="object-cover transition-opacity duration-300 opacity-100" src="{{item.hoverImageUrl}}" style="position: absolute;height: 100%;width: 100%;inset: 0px;color: transparent;"/>
                </figure>
                <!--主图-->
                <figure class="absolute bottom-0 left-0 right-0 top-0 object-cover group-hover-opacity-0">
                  <img class="object-cover transition-opacity duration-300 opacity-100" src="{{item.skuImageUrl}}" style="position: absolute;height: 100%;width: 100%;inset: 0px;color: transparent;"/>
                </figure>
              </a>
        <!--      <ng-container *ngIf="cancelFavoritesId == item.favoritesId">
                <div class="w-full h-40 absolute flex justify-between confirm-box md-hidden">
                  <span class="confirm-text">确定移除心愿单吗？</span>
                  <div style="display: flex;align-items: center; line-height: 40px; overflow: hidden;">
                    <span class="cancel_btn" (click)="cancelDel()">取消</span>
                    <span class="confirm-btn" (click)="delFavoriteItem(item.favoritesId,item)">确认</span>
                  </div>
                </div>
              </ng-container>-->
            </div>
            <div class="details flex w-full flex-col pb-30 px-15 pt-12 col-span-8 sm-col-span-9 lg-p-0 lg-pb-20" [ngClass]="{'lg-pl-20' : productService.isMultipleOfFour(i), 'lg-pr-20' : (productService.isOddNumber(i) && i !== 0)}">
              <a class="mb-4 gap-8 flex items-center justify-between w-full" routerLink="/{{item.frontUrlPath}}">
                <h3 class="truncate font_s_regular" data-testid="heading">{{item.itemName}}</h3>
                <div class="">
                  <ng-container *ngIf="item.approveStatus == 'instock';else onSaleTpl">
                    <span class="whitespace-nowrap text-right font_small_xs_regular text-main item-price">{{init.formatPrice(item.markingPrice) | currency:currency}}</span>
                  </ng-container>
                </div>
              </a>
              <div class="flex h-16 justify-between">
                <div class="flex w-fit justify-between gap-x-20">
                  <div class="gap flex items-end justify-start">
                    <img class="relative mr-8 hidden h-8 w-8 lg-block" style="margin-bottom: 2px;" src="{{item.skuBlockUrl}}"/>
                    <span class="font_small_xxs_medium color_name" style="line-height: 12px;">{{item.colorName}}</span>
                  </div>
                </div>
                <br>
                <div class="flex items-end" *ngIf="selectedFavSizeLists.hasOwnProperty(item.favoritesId) && selectedFavSizeLists[item.favoritesId].qty>0 && selectedFavSizeLists[item.favoritesId].qty<3">
                  <span style="line-height: 12px;" class="whitespace-nowrap text-right font_small_xxs_regular font_small_l_regular text-main is-reduced">库存少于3件</span>
                </div>
              </div>
              <div class="lg-hidden txt_selected_bag" *ngIf="cartItemIds.indexOf(item.itemId) !== -1">
                <span><a class="select-bag" routerLink="/cart">此商品已在购物袋里</a></span>
              </div>
            </div>
            <ng-template #onSaleTpl>
              <ng-container *ngIf="item.markingPrice / 1 > item.price /1;else saleTpl">
                <span class="whitespace-nowrap text-right font_small_xs_regular text-main is-reduced item-price mr-8">{{init.formatPrice(item.price) | currency:currency}}</span>
                <span class="whitespace-nowrap text-right font_small_xs_regular text-main is-deprecated item-price">{{init.formatPrice(item.markingPrice) | currency:currency}}</span>
              </ng-container>
              <ng-template #saleTpl>
                <ng-container *ngIf="salePriceList[item.favoritesId];else mkdTpl">
                  <span class="whitespace-nowrap text-right font_small_xs_regular text-main is-reduced item-price mr-8">{{init.formatPrice(salePriceList[item.favoritesId]) | currency:currency}}</span>
                  <span class="whitespace-nowrap text-right font_small_xs_regular text-main is-deprecated item-price">{{init.formatPrice(item.markingPrice) | currency:currency}}</span>
                </ng-container>
                <ng-template #mkdTpl>
                  <span class="whitespace-nowrap text-right font_small_xs_regular text-main item-price">{{init.formatPrice(item.price) | currency:currency}}</span>
                </ng-template>
              </ng-template>
            </ng-template>
          </div>
          <ng-container *ngIf="cancelFavoritesId == item.favoritesId">
            <div class="h-40 w-full absolute flex justify-between h5-confirm-box lg-hidden">
              <span class="confirm-text">确定移除心愿单吗？</span>
              <div style="display: flex;align-items: center; line-height: 40px; overflow:hidden;">
                <span class="cancel_btn" (click)="cancelDel()">取消</span>
                <span class="confirm-btn" (click)="delFavoriteItem(item.favoritesId,item)">确认</span>
              </div>
            </div>
          </ng-container>
          <div class="details grid h-40 w-full grid-cols-12">
            <button (click)="delFavorite(item.favoritesId)" aria-label="Remove from wishlist" class="col-span-4 sm-col-span-3 z-20 h-40 h-full lg-hidden btn-close h5-bt-none" type="button">
              <svg class="inline h-16 w-16 fill-current"><title>Close</title>
                <use href="/assets/iconSprite.svg#nav-close-desktop"></use>
              </svg>
            </button>
            <div class="col-span-8 sm-col-span-9 flex lg-col-span-full" >
              <div class="detail-container flex w-full">
                <!--pc尺码选择下拉-->
                <!--仅在线商品显示-->
                <div class="w-full flex add_container">
                  <div class="color-section pc-size-section">
                    <div data-component="MDropdown" class="m-dropdown pdp-dropdown is-filled h5-bt-none" (click)="showSize(item.favoritesId)">
                      <button type="button" class="a-button-nostyle placeholder font_si_xs_medium" id="size" aria-label="filter expand/close" aria-haspopup="true" aria-expanded="false">
                        <ng-container *ngIf="selectedFavSizeLists.hasOwnProperty(item.favoritesId) && selectedFavSizeLists[item.favoritesId];else otherBlock;">{{selectedFavSizeLists[item.favoritesId]['valueName']}}</ng-container>
                        <ng-template #otherBlock>选择尺码</ng-template>
                      </button>
                      <button type="button" class="a-button-nostyle a-icon-arrow-down-small a-button-close" role="button" aria-label="expand/close" tabindex="-1">
                        <svg class="inline h-16 w-16 fill-current"><title>Close</title>
                          <use href="/assets/iconSprite.svg#chevron-down"></use>
                        </svg>
                      </button>
                      <ng-container *ngIf="showSizeBox && item.favoritesId==currentFavoritesId">
                        <ul class="list-wrapper" style="display: block;">
                          <li>
                            <ul class="options size_box">
                              <li  *ngFor="let sizeItem of productSizeArray[item.itemId+'-'+item.valueId]" class="a-option size-options size-det-list in-stock font_si_xs_medium" [ngClass]="{'cursor-not-allowed': sizeItem.qty < 1}"  (click)="select_Size($event,item,sizeItem)"> <span class="size-name"> {{productService.sizeConvert(sizeItem.valueName)}} </span></li>
                            </ul>
                          </li>
                        </ul>
                      </ng-container>
                      <span class="error-msg"></span></div>
                  </div>
                  <!--没选择尺码-->
                  <ng-container *ngIf="!selectedFavSizeLists.hasOwnProperty(item['favoritesId']); else otherBlock1;">
                    <ng-container *ngIf="item.approveStatus === 'onsale' && item.qty > 0 ;else remindTpl">
                      <button  type="button" (click)="addShoppingCart(item,1)"class="a-button is-primary add-to-bag-btn add_cart_btn is-disabled font_s_regular " data-coming-soon="COMING SOON" >加入购物袋</button>
                    </ng-container>
                  </ng-container>
                  <!--选择尺码后-->
                  <ng-template #otherBlock1>
                    <ng-container *ngIf="item.approveStatus === 'onsale' && selectedFavSizeLists[item['favoritesId']]['qty'] > 0 ;else remindTpl">
                      <button  type="button" (click)="addShoppingCart(item,1)"class="a-button is-primary add-to-bag-btn add_cart_btn is-disabled font_s_regular" data-coming-soon="COMING SOON" >加入购物袋</button>
                    </ng-container>
                  </ng-template>
                  <ng-template #remindTpl>
                    <!--<button *ngIf="itemsArrivalNoticeConfigList[item.favoritesId]"  type="button" (click)="setArrivalRemind(item)" class="a-button is-primary add-to-bag-btn add_cart_btn is-disabled font_small_xs_medium" data-coming-soon="COMING SOON">到货通知我</button>-->
                    <button type="button" class="a-button is-primary add-to-bag-btn add_cart_btn is-disabled is-reduced font_s_regular" data-coming-soon="COMING SOON">已售罄</button>
                  </ng-template>
                </div>
              </div>

            <!--  <div class="detail-container flex w-full">

              </div>-->
            </div>
          </div>
          <div class="w-full md-hidden font_small_xxs_medium" style="height: 13px; margin-top: 10px;" *ngIf="cartItemIds.indexOf(item.itemId) !== -1">
            <span><a routerLink="/cart">此商品已在购物袋里</a></span>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="emptyFavoriteText">
      <div class="o-cart is-empty">
        <div class="empty-cart">
          <h2 class="a-heading-2 font_s_regular">您的心愿单是空的</h2>
          <a class="a-button continue_btn" routerLink="/">继续购物</a>
        </div>
      </div>
    </ng-container>
    <div *ngIf="showLoading" style="text-align: center; margin-top: 50px;"> 加载中... </div>
  </div>
</div>
<!-- 显示加入购物车报错：加is-select-country is-open -->
<div class="a-overlay js-a-overlay q-opacity-0 q-opacity-95 q-bg-grey-light" [ngClass]="{'is-visible': show_error_box}"
     data-component="AOverlay" (click)="closeOverlay()" id="productAOverlay"></div>
<div data-component="OLightbox" class="o-lightbox is-select-country" [class.is-open]="show_error_box" style="text-align: center">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix">
    <!-- 显示加入购物车报错 开始 -->
    <div class="m-change-country-lightbox false">
      <h2 class="a-heading-2">抱歉！</h2>
      <p class="a-paragraph">{{error_message}}</p>
    </div>
    <!-- 显示加入购物车报错 结束 -->
    <div class="m-button-icon a-icon-close js-close-button" (click)="closeOverlay()">
    </div>
  </div>
</div>

<!--非会员购买提前购商品提示框-->
<div class="a-overlay js-a-overlay q-opacity-0 q-opacity-95 q-bg-grey-light" [ngClass]="{'is-visible': showModal}" (click)="closeOverlay()"></div>
<div data-component="OLightbox" class="o-lightbox is-select-country" [class.is-open]="showModal" style="text-align: center">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix lightbox-content-img">
    <div class="m-change-country-lightbox false">
      <img  src="https://www.cos.cn/img/dm/pageup/190123/300x300.jpg" alt="图片">
      <p>微信扫码或长按图片识别<br>即刻加入<br>“COS摩登衣橱”微信会员计划</p>
    </div>
    <div class="m-button-icon a-icon-close js-close-button" (click)="closeOverlay()"></div>
  </div>
</div>

<!--收藏成功提示框-->
<div class="a-overlay js-a-overlay q-opacity-0 q-opacity-95 q-bg-grey-light" [ngClass]="{'is-visible': remindSuccess || remindError}" data-component="AOverlay" (click)="closeOverlay()"></div>
<div data-component="OLightbox" class="o-lightbox is-select-country" [class.is-open]="remindSuccess || remindError">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix">
    <!-- 弹框 开始 -->
    <div class="m-change-country-lightbox false">
      <div class="paragraph parbase section">
          <span class="richTextSpan">
          <div class="u-cols-sm-12-12 u-cols-md-12-12 u-cols-lg-24-24 u-row u-full-width">
            <div class="contain">
              <div class="u-row u-full-width">
                <div class="content-page page remind" *ngIf="remindSuccess; else zcAndEamil">
                  <h1>扫码关注COS官方微信第一时间获取到货通知</h1>
                  <p><img _ngcontent-c9="" alt="图片" class="qr-image" src="https://100000005-1252208446.file.myqcloud.com/images/qrCode/2021010897667.jpg"></p>
                </div>
                <ng-template #zcAndEamil>
                  <div class="content-page page remind">
                    <h1>抱歉！</h1>
                    <p>{{remindErrorMsg}}</p>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </span>
      </div>
    </div>
    <!-- 弹框 结束 -->
    <div class="m-button-icon a-icon-close js-close-button" (click)="closeOverlay()"></div>
  </div>
</div>

<!-- 弹层：快速加购 -->
<div class="subBox" id="add-to-buy" *ngIf="showBox">
  <app-quick-add [frontCode]="frontCode" [salePrice]="salePrice" (closeBox)="closeLayer()" (showSunCode)="showSunCode()"></app-quick-add>
</div>

<app-favorite-box></app-favorite-box>
